import React, { FC, MouseEvent } from 'react';
import styles from './SelectViewType.module.css';
import { CustomRadioButton } from '../CustomRadioButton';
import { ReactComponent as WithViewIcon } from '../../icons/WithView.svg';
import { ReactComponent as ListOnlyIcon } from '../../icons/ListOnly.svg';
import Box from '@mui/material/Box';

export const SelectViewType: FC<{
  isClientPanelOpen: boolean;
  setPanelView: (e: MouseEvent<HTMLButtonElement | SVGSVGElement>, isOpen: boolean) => void;
}> = ({ isClientPanelOpen, setPanelView }) => {
  return (
    <div className={styles['radio-group']}>
      <Box className={styles['view-radio']}>
        <CustomRadioButton
          radioButtonClass={styles['radio-button']}
          selected={!isClientPanelOpen}
          onClick={(e: MouseEvent<HTMLButtonElement>) => setPanelView(e, false)}
        >
          С просмотром
        </CustomRadioButton>
        <WithViewIcon
          style={{ cursor: 'pointer' }}
          onClick={(e: MouseEvent<SVGSVGElement>) => setPanelView(e, false)}
        />
      </Box>
      <Box className={styles['view-radio']}>
        <CustomRadioButton
          radioButtonClass={styles['radio-button']}
          selected={isClientPanelOpen}
          onClick={(e: MouseEvent<HTMLButtonElement>) => setPanelView(e, true)}
        >
          Только список
        </CustomRadioButton>
        <ListOnlyIcon
          style={{ cursor: 'pointer' }}
          onClick={(e: MouseEvent<SVGSVGElement>) => setPanelView(e, true)}
        />
      </Box>
    </div>
  );
};
