import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Popover from '@mui/material/Popover';
import React, { useRef, useState } from 'react';
import styles from './LeftMenu.module.css';
import { ReactComponent as ArrowDown } from '../icons/ArrowDown.svg';
import { ReactComponent as Exit } from '../icons/Exit.svg';
import { ReactComponent as AppIcon } from '../icons/App.svg';
// import { ReactComponent as FeedBack } from '../icons/FeedBack.svg';
import { ReactComponent as Info } from '../icons/Info.svg';
import { ReactComponent as AvatarIcon } from '../icons/Avatar.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as GroupIcon } from '../icons/Group.svg';
import { ReactComponent as SettingsIcon } from '../icons/Edit.svg';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import {
  setApplicationFormChanged,
  setIsMenuOpen,
  TAppSlice,
  setIsModalOpen,
  setComponentPath,
} from '../redux/appSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { TUserSlice } from '../redux/userSlice';
import { sendLogoutRequest } from '../requests/oidc';
import { Drawer, Modal } from '@mui/material';
import { CLIENT_ID, COPYRIGHT, MANUAL_URL, TRUSTED_VERSION } from '../constants';
import { getImageURL, isOwner } from '../helpers';

const mapStateToProps = (state: RootState) => ({
  isMenuOpen: state.app.isMenuOpen,
  user: state.user.userProfile,
  isMobile: state.app.isMobile,
  applicationFormChanged: state.app.applicationFormChanged,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
  isModalOpen: state.app.isModalOpen,
});

type TLeftMenuProps = {
  isMenuOpen: TAppSlice['isMenuOpen'];
  user: TUserSlice['userProfile'];
  isMobile: TAppSlice['isMobile'];
  applicationFormChanged: TAppSlice['applicationFormChanged'];
  isChangesUnsaved: boolean;
  componentPath: string;
  isModalOpen: boolean;
};

const LeftMenuComponent: React.FC<TLeftMenuProps> = ({
  isMenuOpen,
  user,
  isMobile,
  applicationFormChanged,
  isChangesUnsaved,
  componentPath,
  isModalOpen,
}) => {
  const [expandMore, setExpandMore] = useState(true);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const anchorEl = useRef<HTMLSpanElement>(null);
  const history = useHistory();
  const path = useLocation().pathname;
  const dispatch = useDispatch();
  const onLinkClick = (link: string) => {
    if (isMobile) dispatch(setIsMenuOpen(false));
    history.push(link);
  };
  const handleLogoutClick = () => {
    sendLogoutRequest();
  };
  const closeSaveModal = () => dispatch(setIsModalOpen(false));
  const closeDrawer = () => dispatch(setIsMenuOpen(false));
  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      open={isMenuOpen}
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor="left"
      onClose={closeDrawer}
    >
      <Box className={styles['left-menu']}>
        <Box className={styles['user-profile']}>
          {user.picture ? (
            <div
              style={{
                backgroundImage: `url(${getImageURL(user?.picture)})`,
              }}
              className={styles['user-icon-wrapper']}
              onClick={() => {
                if (applicationFormChanged || isChangesUnsaved) {
                  dispatch(setIsModalOpen(true));
                  dispatch(setComponentPath('/profile'));
                } else {
                  if (isChangesUnsaved) {
                    dispatch(setIsModalOpen(true));
                    dispatch(setComponentPath('/profile'));
                  } else {
                    dispatch(setComponentPath('/profile'));
                    history.push(componentPath);
                  }
                }
              }}
            />
          ) : (
            <Avatar
              className={styles.avatar}
              onClick={() => {
                if (applicationFormChanged || isChangesUnsaved) {
                  dispatch(setComponentPath('/profile'));
                  dispatch(setIsModalOpen(true));
                } else {
                  if (isChangesUnsaved) {
                    dispatch(setIsModalOpen(true));
                    dispatch(setComponentPath('/profile'));
                  } else {
                    dispatch(setComponentPath('/profile'));
                    history.push(componentPath);
                  }
                }
              }}
            >
              <AvatarIcon />
            </Avatar>
          )}

          <Box className={styles['user-info']}>
            <Box>
              <Typography className={clsx(styles.username, 'text-15-medium', 'color-0B1641')}>
                {user.nickname}
              </Typography>
              <Typography className={clsx('text-12', 'color-858BA0', styles.login)}>
                {user.login}
              </Typography>
            </Box>
            <IconButton
              onClick={() => setExpandMore(!expandMore)}
              className={styles['expand-more']}
            >
              <ArrowDown
                fill="#0B1641"
                className={clsx(styles['expand-more-icon'], { [styles.rotate0]: expandMore })}
              />
            </IconButton>
          </Box>
          <Box className={clsx(styles['hidden-buttons'], { [styles['is-expanded']]: expandMore })}>
            <Button
              onClick={handleLogoutClick}
              startIcon={<Exit className={styles['start-icon']} />}
              className={clsx('text-15-regular', 'color-0B1641', styles['hidden-button'])}
            >
              Выход
            </Button>
          </Box>
        </Box>
        <Box className={styles['link-buttons']}>
          <Button
            onClick={() => {
              if (applicationFormChanged || isChangesUnsaved) {
                dispatch(setIsModalOpen(true));
                dispatch(setComponentPath('/applications'));
                return;
              }
              onLinkClick('/applications');
            }}
            startIcon={<AppIcon fill="#0B1641" className={styles['start-icon']} />}
            className={clsx(styles['link-button'], 'text-15-regular', 'color-0B1641', {
              [styles['active-link']]: path.endsWith('/applications'),
            })}
          >
            Приложения
          </Button>
          {isOwner(user.role) && (
            <>
              <Button
                onClick={() => {
                  if (applicationFormChanged) {
                    dispatch(setIsModalOpen(true));
                    dispatch(setComponentPath('/users'));
                    return;
                  }
                  onLinkClick('/users');
                }}
                startIcon={<GroupIcon fill="#0B1641" className={styles['start-icon']} />}
                className={clsx(styles['link-button'], 'text-15-regular', 'color-0B1641', {
                  [styles['active-link']]: path.startsWith('/users'),
                })}
              >
                Пользователи
              </Button>
              <Button
                onClick={() => {
                  if (applicationFormChanged || isChangesUnsaved) {
                    dispatch(setIsModalOpen(true));
                    dispatch(setComponentPath(`/applications/edit/${CLIENT_ID}`));
                    return;
                  }
                  onLinkClick(`/applications/edit/${CLIENT_ID}`);
                }}
                startIcon={<SettingsIcon className={styles['start-icon']} />}
                className={clsx(styles['link-button'], 'text-15-regular', 'color-0B1641', {
                  [styles['active-link']]: path.startsWith(`/applications/edit/${CLIENT_ID}`),
                })}
              >
                Настройки
              </Button>
            </>
          )}
        </Box>
        <Box className={styles['horizontal-line']} />
        <Box>
          <a href={MANUAL_URL}>
            <Button
              startIcon={<Info style={{ marginTop: -2 }} className={styles['start-icon']} />}
              className={styles['help-button']}
            >
              <Typography className={clsx('text-15-regular', 'color-858BA0')}>Справка</Typography>
            </Button>
          </a>
          {/* <Button
            startIcon={<FeedBack style={{ marginTop: -2 }} className={styles['start-icon']} />}
            className={styles['help-button']}
          >
            <Typography className={clsx('text-15-regular', 'color-858BA0')}>
              Отправить отзыв
            </Typography>
          </Button> */}
        </Box>
        <Box className={styles.bottom}>
          <Typography
            ref={anchorEl}
            className={clsx(styles.language, 'text-15-regular', 'color-858BA0')}
            onClick={() => setPopoverOpen(true)}
          >
            Ru
          </Typography>
          <Popover
            classes={{ paper: styles.popover }}
            open={popoverOpen}
            anchorEl={anchorEl.current}
            onClose={() => setPopoverOpen(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Button className={styles['language-button']}>
              <Typography className={clsx('text-14', 'color-0B1641')}>En</Typography>
            </Button>
          </Popover>
          <Typography className={clsx('text-15-regular', 'color-858BA0', styles['margin-top-12'])}>
            Версия {TRUSTED_VERSION}
          </Typography>
          <Typography className={clsx('text-14', 'color-858BA0', styles['margin-top-12'])}>
            {COPYRIGHT}
          </Typography>
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                onLinkClick(componentPath);
                dispatch(setApplicationFormChanged(false));
                closeSaveModal();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};

export const LeftMenu = connect(mapStateToProps)(LeftMenuComponent);
