import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { MiscProviderType, OauthProviderType } from '../redux/services/provider';
import { CreateProvider } from './CreateProvider';
import { CreateProviderByTemplate } from './CreateProviderByTemplate';
import { CreateLdapProvider } from './CreateLdapProvider';
import { BACKEND_URL } from '../constants';
import { TApplication } from '../redux/services/client';
import { CreateEthereumProvider } from './CreateEthereumProvider';

type TCreateProvider = {
  isOpen: boolean;
  close: () => void;
  role: TApplication['role'];
};

export const ChooseProvider: FC<TCreateProvider> = ({ isOpen, close, role }) => {
  const ethereumProviderAvatar = 'public/images/provider/ethereum.svg';

  const providers = [
    {
      type: OauthProviderType.YANDEX,
      name: 'Яндекс',
      pathToAvatar: 'public/images/provider/yandex.svg',
    },
    {
      type: OauthProviderType.VK,
      name: 'ВКонтакте',
      pathToAvatar: 'public/images/provider/vk.svg',
    },
    {
      type: OauthProviderType.MAILRU,
      name: 'Mail.ru',
      pathToAvatar: 'public/images/provider/mail.svg',
    },
    {
      type: OauthProviderType.GOOGLE,
      name: 'Google',
      pathToAvatar: 'public/images/provider/google.svg',
    },
    {
      type: OauthProviderType.KLOUD,
      name: 'Kloud.One',
      pathToAvatar: 'public/images/provider/kloud.svg',
    },
    {
      type: OauthProviderType.CUSTOM,
      name: '',
      pathToAvatar: '',
    },
    {
      type: MiscProviderType.LDAP,
      name: 'LDAP',
      pathToAvatar: 'public/images/provider/ldap.svg',
    },
    {
      type: MiscProviderType.ETHEREUM,
      name: 'Ethereum',
      pathToAvatar: ethereumProviderAvatar,
    },
  ];

  const [providerTemplate, setProviderTemplate] = useState({
    type: OauthProviderType.CUSTOM,
    name: '',
    pathToAvatar: '',
  });

  const [isCreateFormTemplateOpen, setIsCreateFormTemplateOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isCreateFormLdapOpen, setIsCreateFormLdapOpen] = useState(false);
  const [isCreateFormEthereumOpen, setIsCreateFormEthereumOpen] = useState(false);

  const handleClose = () => {
    close();
  };

  return (
    <>
      <Drawer
        classes={{ paper: styles['drawer-paper'] }}
        BackdropProps={{ className: styles.backdrop }}
        onClose={handleClose}
        open={isOpen}
        anchor="right"
        variant="temporary"
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа
            </Typography>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['choose-provider']}>
            <Typography
              className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
            >
              Выберите шаблон способа входа
            </Typography>
            {providers.map((provider, index) => {
              return (
                <ListItem
                  key={Date.now() + index}
                  className={styles.provider}
                  onClick={() => {
                    if (provider.type === OauthProviderType.CUSTOM) {
                      setIsCreateFormOpen(true);
                    } else if (provider.type === MiscProviderType.LDAP) {
                      setIsCreateFormLdapOpen(true);
                    } else if (provider.type === MiscProviderType.ETHEREUM) {
                      setIsCreateFormEthereumOpen(true);
                    } else if (
                      provider.type !== MiscProviderType.CREDENTIALS &&
                      provider.type !== MiscProviderType.EMAIL
                    ) {
                      setProviderTemplate({
                        ...providerTemplate,
                        type: provider.type,
                        name: provider.name,
                        pathToAvatar: provider.pathToAvatar,
                      });
                      setIsCreateFormTemplateOpen(true);
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.pathToAvatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.pathToAvatar && <IdIcon />}
                  </div>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                    {provider.name || 'OpenID Connect'}
                  </Typography>
                </ListItem>
              );
            })}
          </div>
        </div>
      </Drawer>
      <CreateProviderByTemplate
        role={role}
        isOpen={isCreateFormTemplateOpen}
        close={() => setIsCreateFormTemplateOpen(false)}
        providerTemplate={providerTemplate}
      />
      <CreateProvider
        role={role}
        isOpen={isCreateFormOpen}
        close={() => setIsCreateFormOpen(false)}
      />
      <CreateLdapProvider
        role={role}
        isOpen={isCreateFormLdapOpen}
        close={() => setIsCreateFormLdapOpen(false)}
      />
      <CreateEthereumProvider
        role={role}
        pathToAvatar={ethereumProviderAvatar}
        isOpen={isCreateFormEthereumOpen}
        close={() => setIsCreateFormEthereumOpen(false)}
      />
    </>
  );
};
