import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import {
  TUserProfile,
  TUserSlice,
  setUpdateExternalAccounts,
  setUserProfile,
} from '../../redux/userSlice';
import React, { FC, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './UserProfile.module.css';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as MailIcon } from '../../icons/Mail.svg';
import { ReactComponent as LdapIcon } from '../../icons/Ldap.svg';
import { Link, useHistory } from 'react-router-dom';
import {
  convertUserProfile,
  exportToJson,
  getImageURL,
  getMonthByNumber,
  isAdministrator,
} from '../../helpers';
import {
  TExternalAccount,
  useGetExternalAccountsQuery,
  useDeleteExternalAccountMutation,
  AccountTypes,
  useGetPrivateClaimsQuery,
  useSetMainEmailMutation,
} from '../../redux/services/user';
import { BACKEND_URL, CUSTOM_USER_FIELDS, DATA_PROCESSING_POLICY_URL } from '../../constants';
import { PublicStatusPopover } from './PublicStatusPopover';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { getUserProfile } from '../../requests/oidc';

const mapStateToProps = ({ user }: RootState) => ({
  userProfile: user.userProfile,
  updateExternalAccounts: user.updateExternalAccounts,
});

type TUserProfileComponent = {
  userProfile: TUserSlice['userProfile'];
  updateExternalAccounts: boolean;
};

const UserProfileComponent: FC<TUserProfileComponent> = ({
  userProfile,
  updateExternalAccounts,
}) => {
  const date = userProfile.birthdate ? new Date(userProfile.birthdate) : null;
  const passwordUpdateDate = new Date(userProfile.password_updated_at || '');
  const history = useHistory();
  const { data: externalAccounts, refetch } = useGetExternalAccountsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const { public_profile_claims } = privateClaims || {};
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateExternalAccounts) {
      dispatch(setUpdateExternalAccounts(false));
      refetch();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}
        >
          Профиль
        </Typography>
        <Typography style={{ marginBottom: 24 }} className={clsx('text-14', 'color-858BA0')}>
          Вы можете управлять доступом к вашему аккаунту. Настройте какие данные будут предоставлены
          приложениям после вашего согласия.
        </Typography>
        <div className={styles.panel}>
          <div className={styles['panel-title']}>
            <Typography className={clsx('header-3', 'font-golos')}>Основная информация</Typography>
            <Link
              to="/profile/edit"
              className={styles['margin-right']}
              style={{ marginLeft: 'auto', textDecoration: 'none' }}
            >
              <Button variant="custom2">Изменить</Button>
            </Link>
          </div>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичное имя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.nickname ? userProfile.nickname : 'Нет имени'}
                </Typography>
              </div>
              <PublicStatusPopover
                isPublic={!!public_profile_claims?.includes('nickname')}
                claims="nickname"
                disabled
              />
            </div>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Фото профиля
              </Typography>
              {userProfile.picture ? (
                <div
                  style={{
                    backgroundImage: `url(${getImageURL(userProfile?.picture)})`,
                  }}
                  className={styles['user-icon-wrapper']}
                />
              ) : (
                <Avatar className={styles.avatar}>
                  <AvatarIcon />
                </Avatar>
              )}
              <PublicStatusPopover
                isPublic={!!public_profile_claims?.includes('picture')}
                claims="picture"
                disabled
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  ID пользователя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.id}
                </Typography>
              </div>
              <PublicStatusPopover
                isPublic={!!public_profile_claims?.includes('id')}
                claims="id"
                disabled
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Имя и фамилия
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {((userProfile.given_name || '') + ' ' + (userProfile.family_name || '')).trim() ||
                    'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                isPublic={
                  !!public_profile_claims?.includes('family_name') ||
                  !!public_profile_claims?.includes('given_name')
                }
                claims="family_name given_name"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Логин
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.login}
                </Typography>
              </div>
              <PublicStatusPopover
                isPublic={!!public_profile_claims?.includes('login')}
                claims="login"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Дата рождения
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {date
                    ? `0${date.getDate()}`.slice(-2) +
                      ` ${getMonthByNumber(date.getMonth())} ${date.getFullYear()} г.`
                    : 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                isPublic={!!public_profile_claims?.includes('birthdate')}
                claims="birthdate"
                userId={userProfile.id}
              />
            </div>
            {CUSTOM_USER_FIELDS &&
              Object.keys(CUSTOM_USER_FIELDS).map(
                (key) =>
                  userProfile[key as keyof TUserProfile] && (
                    <>
                      <div key={key} className={styles['info-item']}>
                        <Typography
                          className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                        >
                          {CUSTOM_USER_FIELDS?.[key]?.['title']}
                        </Typography>
                        <Typography
                          className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                        >
                          {userProfile[key as keyof TUserProfile]}
                        </Typography>
                        <PublicStatusPopover
                          isPublic={!!public_profile_claims?.includes(key)}
                          claims={key}
                          userId={userProfile.id}
                        />
                      </div>
                    </>
                  ),
              )}
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичность
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  Профиль не участвует в поиске
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.justify}>
            <Typography className={clsx('text-17-regular', 'font-golos')}>Способы входа</Typography>
            <Button
              variant="custom2"
              className={styles['add-button']}
              onClick={() => history.push('/profile/external-provider')}
            >
              Добавить
            </Button>
          </div>
          <div className={styles.info}>
            {externalAccounts?.map((account) => (
              <ExternalAccount userProfile={userProfile} key={account.sub} account={account} />
            ))}
          </div>
        </div>
        <div
          className={styles.panel}
          style={{ display: 'flex', paddingBottom: 22, alignItems: 'center' }}
        >
          <div style={{ paddingTop: 22 }}>
            <Typography
              style={{ marginBottom: '4px' }}
              className={clsx('text-17-regular', 'font-golos')}
            >
              Пароль
            </Typography>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              {`0${passwordUpdateDate.getDate()}`.slice(-2) +
                ` ${getMonthByNumber(
                  passwordUpdateDate.getMonth(),
                )} ${passwordUpdateDate.getFullYear()} г.`}
            </Typography>
          </div>
          <Button
            onClick={() => history.push('/profile/change-password')}
            variant="custom2"
            style={{ marginLeft: 'auto' }}
            className={styles['margin-right']}
          >
            Изменить
          </Button>
        </div>
        <Accordion className={clsx(styles.panel, styles.accordion)}>
          <AccordionSummary
            className={styles['accorion-summary']}
            classes={{ content: styles['accorion-summary-content'] }}
            expandIcon={<ArrowDownIcon fill="#0B1641" />}
          >
            <Typography className={clsx('text-17-regular', 'font-golos')}>
              Другие действия
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={styles['accordion-details']}
          >
            {/* #293
            <Button 
              variant="custom2"
              style={{ textAlign: 'left' }}
              className={styles['margin-bottom']}
            >
              {isAdministrator(userProfile.role)
                ? 'Отказаться от прав администратора'
                : 'Получить права администратора'}
            </Button> */}
            <Button
              variant="custom2"
              className={styles['margin-bottom']}
              onClick={async () => {
                await deleteAllSession(userProfile.id);
                window.location.reload();
              }}
            >
              Выйти со всех устройств
            </Button>
            <a href={DATA_PROCESSING_POLICY_URL}>
              <Button variant="custom2" className={styles['margin-bottom']}>
                Политика обработки ПДн
              </Button>
            </a>
            <Button
              onClick={() => exportToJson(userProfile, 'profile.json')}
              variant="custom2"
              className={styles['margin-bottom']}
            >
              Скачать данные
            </Button>
            <Button
              onClick={() => history.push('/profile/delete')}
              variant="custom2"
              className={styles['margin-bottom']}
            >
              Удалить аккаунт
            </Button>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

type ExternalAccountProps = {
  account: TExternalAccount;
  userProfile: TUserProfile;
};

const ExternalAccount: FC<ExternalAccountProps> = ({
  account: { user_id, sub, given_name, family_name, type, avatar, email, id },
  userProfile: { email: mainEmail, id: userId },
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorId, setAnchorId] = useState<string | null>(null);
  const [deleteExternalAccount] = useDeleteExternalAccountMutation();
  const [setMainEmail] = useSetMainEmailMutation();
  const history = useHistory();
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userId), {
    skip: !userId,
  });
  const { public_external_accounts_ids } = privateClaims || {};
  const dispatch = useDispatch();

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>, id?: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (id) setAnchorId(id);
  };

  const getAvatar = () => {
    if (avatar)
      return <img className={styles['provider-icon']} src={avatar} width="40" height="40" />;

    switch (type) {
      case AccountTypes.EMAIL:
        return <MailIcon />;
      case AccountTypes.KLOUD:
        return <AccountTypesBlock pathToImage="/public/images/provider/kloud.svg" />;
      case AccountTypes.YANDEX:
        return <AccountTypesBlock pathToImage="/public/images/provider/yandex.svg" />;
      case AccountTypes.VK:
        return <AccountTypesBlock pathToImage="/public/images/provider/vk.svg" />;
      case AccountTypes.GOOGLE:
        return <AccountTypesBlock pathToImage="/public/images/provider/google.svg" />;
      case AccountTypes.MAILRU:
        return <AccountTypesBlock pathToImage="/public/images/provider/mail.svg" />;
      case AccountTypes.LDAP:
        return <LdapIcon />;
      case AccountTypes.ETHEREUM:
        return <Jazzicon diameter={40} seed={jsNumberForAddress(sub)} />;
      default:
        return <IdIcon />;
    }
  };

  const getLabel = () => {
    switch (type) {
      case AccountTypes.EMAIL:
        return email;
      case AccountTypes.KLOUD:
      case AccountTypes.ETHEREUM:
        return sub;
      default:
        return (given_name || '') + ' ' + (family_name || '');
    }
  };

  const handleClosePopover = (event: MouseEvent<ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setAnchorId(null);
  };

  const handleDeleteClick = async () => {
    await deleteExternalAccount({ userId: user_id, accountId: id });
    setAnchorEl(null);
    setAnchorId(null);
  };

  const handleSetMainEmail = async (email: string) => {
    await setMainEmail({ email, userId: String(userId) });
    setAnchorEl(null);
    setAnchorId(null);
    const profile = await getUserProfile();
    if (profile) {
      const convertedProfile = convertUserProfile(profile);
      dispatch(setUserProfile(convertedProfile));
    }
  };

  return (
    <div className={styles['info-item']} style={{ justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', width: 'calc(100% - 119px)' }}>
        <div className={styles['provider-icon']}>{getAvatar()}</div>
        <div className={styles['provider-info']}>
          <Typography className={clsx('text-14', 'color-B1641', styles.ellipsis)}>
            {getLabel()}
          </Typography>
          <Typography className={clsx('text-12', 'color-858BA0')}>{type}</Typography>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <IconButton
          onClick={(event) => handleOpenPopover(event, sub || email)}
          className={clsx(styles['actions-icon'], {
            [styles['active-actions-icon']]: anchorId && (anchorId === sub || anchorId === email),
          })}
        >
          <ActionsIcon />
        </IconButton>
        <PublicStatusPopover
          userId={String(user_id)}
          isPublic={!!public_external_accounts_ids?.includes(parseInt(id, 10))}
          publicExternalAccounts={[parseInt(id, 10)]}
        />
      </div>
      <Popover
        classes={{
          paper: clsx(styles.paper),
        }}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Button
          className={clsx(styles['popover-button'])}
          onClick={() => {
            type === AccountTypes.EMAIL && email === mainEmail
              ? history.push('profile/email/change')
              : handleDeleteClick();
          }}
        >
          <Typography className={clsx('text-14', 'color-0B1641')}>
            {type === AccountTypes.EMAIL && email === mainEmail ? 'Изменить' : 'Удалить'}
          </Typography>
        </Button>
        {type === AccountTypes.EMAIL && email !== mainEmail && (
          <Button
            className={clsx(styles['popover-button'])}
            onClick={() => {
              if (email) handleSetMainEmail(email);
            }}
          >
            <Typography className={clsx('text-14', 'color-0B1641')}>Сделать основной</Typography>
          </Button>
        )}
      </Popover>
    </div>
  );
};

type AccountTypesProps = {
  pathToImage: string;
};

const AccountTypesBlock: FC<AccountTypesProps> = ({ pathToImage }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${BACKEND_URL + pathToImage})`,
      }}
      className={styles['provider-icon-wrapper']}
    />
  );
};

export const UserProfile = connect(mapStateToProps)(UserProfileComponent);
