import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';
import { TUserProfile } from '../userSlice';
import { MailCodeTypes } from './mail';

export type TExternalAccount = {
  id: string;
  avatar?: string;
  email?: string;
  family_name?: string;
  given_name?: string;
  issuer: string;
  sub: string;
  type: AccountTypes;
  user_id: number;
  update_password_at: string;
};

export type PrivateClaims = {
  scopes: string;
  public_profile_claims?: string;
  public_external_accounts_ids?: number[];
};

export enum AccountTypes {
  GOOGLE = 'GOOGLE',
  YANDEX = 'YANDEX',
  VK = 'VK',
  MAILRU = 'MAILRU',
  CUSTOM = 'CUSTOM',
  EMAIL = 'EMAIL',
  KLOUD = 'KLOUD',
  LDAP = 'LDAP',
  ETHEREUM = 'ETHEREUM',
}

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['Claims', 'ExternalAccounts'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/user/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getExternalAccounts: builder.query<TExternalAccount[], string>({
      query: (user_id) => ({
        url: `/external_account/${user_id}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ExternalAccounts' as const,
                id,
              })),
              { type: 'ExternalAccounts', id: 'LIST' },
            ]
          : [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    changeEmail: builder.mutation<
      void,
      {
        email: string;
        userId: string;
        code: string;
        type: MailCodeTypes;
        rebind: boolean;
      }
    >({
      query: ({ email, userId, code, type, rebind }) => {
        return {
          url: `change_email/${userId}`,
          method: 'PUT',
          body: 'code=' + code + '&email=' + email + '&code_type=' + type + '&rebind=' + rebind,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    addEmail: builder.mutation<
      void,
      {
        newEmail: string;
        userId: string;
        code: string;
        rebind: boolean;
      }
    >({
      query: ({ newEmail, userId, code, rebind }) => {
        return {
          url: `add_email/${userId}`,
          method: 'POST',
          body:
            'code=' +
            code +
            '&email=' +
            newEmail +
            '&code_type=' +
            MailCodeTypes.addEmail +
            '&rebind=' +
            rebind,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    changePassword: builder.mutation<
      void,
      {
        newPassword: string;
        userId: string;
        revokeTokens?: boolean;
      }
    >({
      query: ({ newPassword, userId, revokeTokens }) => {
        return {
          url: `password/${userId}`,
          method: 'PUT',
          body: 'password=' + newPassword + (revokeTokens ? '&revoke_tokens=' + revokeTokens : ''),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
    }),

    restoreProfile: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => {
        return {
          method: 'PUT',
          url: `/restore/${userId}`,
        };
      },
    }),

    deleteExternalAccount: builder.mutation<void, { userId: number; accountId: string }>({
      query: ({ userId, accountId }) => {
        return {
          method: 'DELETE',
          url: `/external_account/${userId}/${accountId}`,
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    setMainEmail: builder.mutation<void, { email: string; userId: string }>({
      query: ({ email, userId }) => {
        return {
          method: 'PUT',
          url: `/set_main_email/${userId}`,
          body: { email },
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    bindLdapAccount: builder.mutation<
      { success: boolean; nickname?: string },
      { userId: string; username: string; password: string; provider_id: string; rebind?: boolean }
    >({
      query: ({ userId, ...body }) => {
        return {
          method: 'PUT',
          url: `/add_ldap_account/${userId}`,
          body,
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    bindEthereumAccount: builder.mutation<
      { success: boolean; nickname?: string; binded_to_this_user: boolean },
      { userId: string; address: string; signature: string; rebind?: boolean }
    >({
      query: ({ userId, ...body }) => {
        return {
          method: 'PUT',
          url: `/add_ethereum_account/${userId}`,
          body,
        };
      },
      invalidatesTags: [{ type: 'ExternalAccounts', id: 'LIST' }],
    }),

    getPrivateClaims: builder.query<PrivateClaims, string>({
      query: (user_id) => ({
        url: `/private_scopes/${user_id}`,
      }),
      providesTags: [{ type: 'Claims', id: 'LIST' }],
    }),

    changeClaimPrivacy: builder.mutation<
      void,
      {
        userId: string;
        isPublic: boolean;
        claims?: string;
        publicExternalAccounts?: TUserProfile['public_external_accounts_ids'];
      }
    >({
      query: ({ claims, isPublic, userId, publicExternalAccounts }) => {
        const body = {
          mode: isPublic ? 'SET_PRIVATE' : 'SET_PUBLIC',
          public_profile_claims: claims,
          public_external_accounts_ids: publicExternalAccounts,
        };

        return {
          url: BACKEND_URL + '/api/user/v1/private_scopes/' + userId,
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body,
        };
      },
      invalidatesTags: [{ type: 'Claims', id: 'LIST' }],
    }),
  }),
});

export const {
  useChangeEmailMutation,
  useGetExternalAccountsQuery,
  useLazyGetExternalAccountsQuery,
  useDeleteExternalAccountMutation,
  useBindLdapAccountMutation,
  useBindEthereumAccountMutation,
  useGetPrivateClaimsQuery,
  useChangeClaimPrivacyMutation,
  useChangePasswordMutation,
  useAddEmailMutation,
  useRestoreProfileMutation,
  useSetMainEmailMutation,
} = userApi;
