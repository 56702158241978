import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import styles from './ApplicationRightPanelProfile.module.css';
import { TApplication } from '../../redux/services/client';
import { ReactComponent as UrlIcon } from '../../icons/Url.svg';
import { ReactComponent as WatchIcon } from '../../icons/Watch.svg';
import { ReactComponent as InfoIcon } from '../../icons/Info.svg';
import { ReactComponent as EyeIcon } from '../../icons/Eye.svg';
import { ReactComponent as EyeOffIcon } from '../../icons/EyeOff.svg';
import { ReactComponent as CalendarIcon } from '../../icons/Calendar2.svg';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

import { getScopeProps } from '../../utils/getScopeProps';
import Box from '@mui/material/Box';
import { IconWrapper } from '../IconWrapper';
import { createFullDateString, isOwnerOrEditor } from './../../helpers';

type TApplicationRightPanelProfile = {
  application?: TApplication;
  scopesData?: { scopes?: string; createdAt?: string };
  panelRef: RefObject<HTMLDivElement>;
  variant?: string;
};

export const ApplicationRightPanelProfile: FC<TApplicationRightPanelProfile> = ({
  application,
  scopesData,
  panelRef,
  variant,
}) => {
  const [fullText, setFullText] = useState(true);
  const descriptionElement = useRef<HTMLSpanElement | null>(null);
  const { scopes, createdAt } = scopesData || {};
  const onRef = (node: HTMLSpanElement) => {
    if (!descriptionElement.current) {
      descriptionElement.current = node;
      if ((node?.clientHeight || 0) > 80) setFullText(false);
    }
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    descriptionElement.current = null;
    setFullText(true);
  }, [application]);

  useEffect(() => {
    if (variant === 'admin') {
      const preventDefault = (e: Event) => {
        if ((panelRef.current?.scrollTop || 0) < 150) e.preventDefault();
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const scrollPanel = (e: any) => {
        const deltaY = e.deltaY;
        if ((panelRef.current?.scrollTop as number) < 150) {
          panelRef.current?.scrollBy({
            top: deltaY * 10,
            behavior: 'smooth',
          });
        } else if (wrapperRef.current?.scrollTop === 0) {
          panelRef.current?.scrollBy({
            top: deltaY * 10,
            behavior: 'smooth',
          });
        }
      };
      wrapperRef.current?.addEventListener('wheel', preventDefault, { passive: false });
      wrapperRef.current?.addEventListener('wheel', scrollPanel);
      return () => {
        wrapperRef.current?.removeEventListener('wheel', preventDefault);
        wrapperRef.current?.removeEventListener('wheel', scrollPanel);
      };
    }
  }, [variant]);

  return (
    <Box
      ref={wrapperRef}
      className={clsx(styles.wrapper, {
        [styles['admin-wrapper']]: application?.role,
      })}
    >
      {application?.client && (
      <div className={styles.info}>
        <Typography className={clsx('header-3', 'font-golos', styles['info-title'])}>
          Информация о приложении
        </Typography>
        {application?.client?.description && (
          <div className={styles['flex-container']}>
            <IconWrapper>
              <InfoIcon className={styles['info-icon']} />
            </IconWrapper>
            <div className={styles['client-description-wrapper']}>
              <Typography
                ref={onRef}
                className={clsx(
                  'text-14',
                  'color-0B1641',
                  styles['info-item'],
                  styles['client-description'],
                  {
                    [styles['max-height-80']]: !fullText,
                  },
                )}
              >
                {application.client?.description}
              </Typography>
              {!fullText && (
                <Button
                  className={clsx('color-4C6AD4', 'text-14', styles['show-full'])}
                  onClick={() => setFullText(true)}
                >
                  Показать полностью
                </Button>
              )}
            </div>
          </div>
        )}
        {application?.client?.domain && (
          <div className={clsx(styles['flex-container'], styles['align-center'])}>
            <IconWrapper>
              <UrlIcon />
            </IconWrapper>
            <Link
              href={application.client?.domain}
              underline="hover"
              className={clsx('text-14', 'color-4C6AD4', styles['info-item'])}
            >
              {application.client?.domain}
            </Link>
          </div>
        )}
        {createdAt && (
          <div className={clsx(styles['flex-container'], styles['align-center'])}>
            <IconWrapper>
              <WatchIcon />
            </IconWrapper>
            <Typography className={clsx('text-14', 'color-0B1641', styles['info-item'])}>
              Доступ предоставлен {createFullDateString(new Date(createdAt))}
            </Typography>
          </div>
        )}
        {isOwnerOrEditor(application?.role) && (
          <>
            <div className={clsx(styles['flex-container'], styles['align-center'])}>
              <IconWrapper>
                {application?.client.is_visible ? (
                  <EyeIcon className={styles['eye-icon']} />
                ) : (
                  <EyeOffIcon />
                )}
              </IconWrapper>
              <div>
                <Typography className={clsx('text-14', 'color-0B1641')}>
                  {application?.client.is_visible ? 'Видимое приложение' : 'Скрытое приложение'}
                </Typography>
                <Typography className={clsx('text-12', 'color-858BA0')}>
                  {application?.client.is_visible
                    ? 'Приложение можно найти в поиске'
                    : 'Приложение нельзя найти в поиске'}
                </Typography>
              </div>
            </div>
            {application?.client.created_at && (
              <div className={clsx(styles['flex-container'], styles['align-center'])}>
                <IconWrapper>
                  <CalendarIcon />
                </IconWrapper>
                <Typography className={clsx('text-14', 'color-0B1641')}>
                  Создано {createFullDateString(new Date(application.client.created_at))}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
      )}

      {scopes && (
        <div className={styles.info}>
          <Typography className={clsx('header-3', 'font-golos', styles['info-title'])}>
            Предоставленные разрешения
          </Typography>
          {scopes.split(' ').map((scope, index) => {
            const scopeProps = getScopeProps(scope);
            return (
              scopeProps && (
                <div key={index} className={clsx(styles['flex-container'], styles['align-center'])}>
                  <div className={styles['info-icon-wrapper']}>
                    <scopeProps.icon />
                  </div>
                  <Typography className={clsx('text-14', 'color-0b1641', styles['info-item'])}>
                    {scopeProps.description}
                  </Typography>
                </div>
              )
            );
          })}
        </div>
      )}
    </Box>
  );
};
