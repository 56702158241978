import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { setIsEventLogFilterOpen, TAppSlice } from '../redux/appSlice';
import { setFilter, setFilterCount, TFilter } from '../redux/userSlice';
import styles from './EventLogFilter.module.css';
import clsx from 'clsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ruLocale from 'date-fns/locale/ru';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('ru', ruLocale);
import { isObjectEmpty } from '../helpers';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowDropDownIcon } from '../icons/ArrowDropDown.svg';
import { ReactComponent as CheckIcon } from '../icons/CheckMark.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';

const schema = yup.object({
  start_date: yup
    .date()
    .max(new Date(), 'Дата начала не может быть позднее сегодняшнего дня')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  end_date: yup
    .date()
    .min(yup.ref('start_date'), 'Дата начала превышает дату окончания')
    .max(new Date(), 'Дата окончания не может быть позднее сегодняшнего дня')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  client_id: yup.string().matches(/^[^\n ]*$/, {
    message: 'Поле Client_id не может содержать пробелы',
  }),
  user_id: yup.string().matches(/^[0-9]*$/, {
    message: 'Поле User_id должно состоять из цифр',
  }),
  ip_address: yup.string().matches(/^[^\n ]*$/, {
    message: 'Поле IP не может содержать пробелы',
  }),
  event: yup.string(),
  description: yup.string(),
});

type TEventLogFilterProps = {
  isEventLogFilterOpen: TAppSlice['isEventLogFilterOpen'];
  role: string | undefined;
};

const mapStateToProps = (state: RootState) => ({
  isEventLogFilterOpen: state.app.isEventLogFilterOpen,
});

const EventLogFilterComponent: FC<TEventLogFilterProps> = ({ isEventLogFilterOpen, role }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useForm<TFilter>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      level: 'ALL',
      start_date: '',
      end_date: '',
    },
  });

  const levelVariants = [
    {
      name: 'Все',
      value: 'ALL',
    },
    {
      name: 'Информация',
      value: 'INFO',
    },
    {
      name: 'Ошибка',
      value: 'ERROR',
    },
  ];

  const watchFields = watch([
    'level',
    'start_date',
    'end_date',
    'client_id',
    'user_id',
    'ip_address',
    'event',
    'description',
  ]);
  const selectedFilters = watchFields.filter((item) => {
    return !(item === '' || item === undefined || item === 'ALL');
  });
  const watchLevel = watch('level');
  const watchStartDate = watch('start_date');
  const watchEndDate = watch('end_date');

  const dispatch = useDispatch();
  const closeDrawer = () => {
    dispatch(setIsEventLogFilterOpen(false));
  };

  const onSubmit: SubmitHandler<TFilter> = (data) => {
    const formData = {
      ...data,
      start_date: data.start_date ? new Date(data.start_date).toISOString() : '',
      end_date: data.end_date ? new Date(data.end_date).toISOString() : '',
    };
    dispatch(setFilter(formData));
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      open={isEventLogFilterOpen}
      anchor="right"
      onClose={closeDrawer}
      BackdropProps={{ invisible: true }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <div className={styles.header}>
          <Typography className={clsx('header-3', 'font-golos', 'color-0B1641')}>
            Фильтрация
          </Typography>
          <IconButton onClick={closeDrawer} className={styles['close-button']}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles['filter-form']}>
          <Typography style={{ marginBottom: 8 }} className={clsx('text-14', 'color-0B1641')}>
            Уровень
          </Typography>
          <Select
            IconComponent={ArrowDropDownIcon}
            MenuProps={{ classes: { paper: styles.menu } }}
            className={styles.select}
            classes={{ outlined: styles.outlined }}
            sx={{
              '&.Mui-focused fieldset': {
                borderColor: '#606783 !important',
              },
              '&.Mui-error fieldset': {
                borderColor: '#FC545C !important',
              },
            }}
            value={watchLevel}
            onChange={(e) => setValue('level', e.target.value)}
          >
            {levelVariants.map((variant) => (
              <MenuItem
                key={variant.value}
                value={variant.value}
                classes={{
                  root: styles['select-item'],
                  selected: styles['select-item-selected'],
                }}
              >
                {variant.name}
                <CheckIcon className={styles['check-icon']} />
              </MenuItem>
            ))}
          </Select>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Дата и время
          </Typography>
          <Controller
            control={control}
            name="start_date"
            defaultValue=""
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(newValue) => field.onChange(newValue?.toISOString())}
                maxDate={watchEndDate ? new Date(watchEndDate as string) : new Date()}
                dateFormat="dd.MM.yyyy p"
                locale="ru"
                placeholderText="дд.мм.гггг   00:00"
                showTimeInput
                timeInputLabel="Время:"
                strictParsing={true}
                className={clsx(styles['date-picker'], {
                  [styles['date-picker__error']]: errors.start_date,
                })}
              />
            )}
          />
          <Controller
            control={control}
            name="end_date"
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(newValue) => field.onChange(newValue?.toISOString())}
                minDate={new Date(watchStartDate as string)}
                maxDate={new Date()}
                dateFormat="dd.MM.yyyy p"
                locale="ru"
                placeholderText="дд.мм.гггг   00:00"
                showTimeInput
                timeInputLabel="Время:"
                className={clsx(styles['date-picker'], {
                  [styles['date-picker__error']]: errors.end_date,
                })}
              />
            )}
          />
          {(errors.start_date || errors.end_date) && (
            <Typography className={styles['error-message']}>
              {errors?.start_date?.message || errors?.end_date?.message}
            </Typography>
          )}
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Приложение (Client_id)
          </Typography>
          <TextField
            {...register('client_id', {
              onChange: () => {
                if (errors.client_id) clearErrors('client_id');
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.client_id}
            helperText={errors.client_id ? errors.client_id.message : ''}
            fullWidth
            variant="standard"
          />
          {role !== '' && (
            <>
              <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                Пользователь (User_id)
              </Typography>
              <TextField
                {...register('user_id', {
                  onChange: () => {
                    if (errors.user_id) clearErrors('user_id');
                  },
                })}
                className="custom"
                FormHelperTextProps={{
                  className: clsx('text-14', 'color-858BA0'),
                }}
                error={!!errors.user_id}
                helperText={errors.user_id ? errors.user_id.message : ''}
                fullWidth
                variant="standard"
              />
            </>
          )}
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Местоположение (IP)
          </Typography>
          <TextField
            {...register('ip_address', {
              onChange: () => {
                if (errors.ip_address) clearErrors('ip_address');
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.ip_address}
            helperText={errors.ip_address ? errors.ip_address.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Событие
          </Typography>
          <TextField {...register('event')} className="custom" fullWidth variant="standard" />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Описание
          </Typography>
          <TextField {...register('description')} className="custom" fullWidth variant="standard" />
        </div>
        <div className={styles['bottom-buttons']}>
          <Button
            style={{ marginRight: 16, paddingRight: 16, whiteSpace: 'nowrap', color: '#0B1641' }}
            variant="custom"
            color="secondary"
            disabled={!selectedFilters.length}
            onClick={() => {
              reset();
            }}
          >
            Сбросить фильтр
            {selectedFilters.length ? (
              <div className={styles.count}>{selectedFilters.length}</div>
            ) : (
              ''
            )}
          </Button>
          <Button
            variant="custom"
            type="submit"
            onClick={() => {
              if (isObjectEmpty(errors)) closeDrawer();
              dispatch(setFilterCount(selectedFilters.length));
            }}
          >
            Применить
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export const EventLogFilter = connect(mapStateToProps)(EventLogFilterComponent);
