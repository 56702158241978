import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './WidgetColor.module.css';
import ColorPicker from './ColorPicker';
import { TEditAppicationInputs } from './applications/EditApplication';
import { BACKEND_URL } from '../constants';
import { replaceJSX } from '../helpers';
import { useWatch, useFormContext } from 'react-hook-form';

type TWidgetColorComponent = {
  avatarSrc: string | null;
  showAvatar: boolean;
  name: string;
};

export const WidgetColor: FC<TWidgetColorComponent> = ({ showAvatar, avatarSrc, name }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<TEditAppicationInputs>();
  const widgetTitle = useWatch({ control, name: 'widget_title' });
  const buttonColor = useWatch({ control, name: 'widget_colors.button_color' });
  const fontColor = useWatch({ control, name: 'widget_colors.font_color' });
  const WIDGET_APP_NAME = name;

  return (
    <div className={styles['widget-color']}>
      <Typography className={clsx('color-#0B1641', 'text-14', styles['color-title'])}>
        Заголовок виджета
      </Typography>
      <TextField
        {...register('widget_title')}
        className="custom"
        fullWidth
        variant="standard"
        style={{ marginBottom: 32 }}
      />
      <div className={styles['flex-container']}>
        <div className={styles['set-colors-container']}>
          <div className={styles['set-color']}>
            <Typography className={clsx('color-#0B1641', 'text-14', styles['color-title'])}>
              Цвет кнопок
            </Typography>
            <ColorPicker componentName="widget_colors" colorName="button_color" />
            {errors.widget_colors?.button_color && (
              <Typography className={clsx('text-14', styles['color-error'])}>
                {errors.widget_colors?.button_color.message}
              </Typography>
            )}
          </div>
          <div className={styles['set-color']}>
            <Typography className={clsx('color-#0B1641', 'text-14', styles['color-title'])}>
              Цвет шрифта на кнопках
            </Typography>
            <ColorPicker componentName="widget_colors" colorName="font_color" />
            {errors.widget_colors?.font_color && (
              <Typography className={clsx('text-14', styles['color-error'])}>
                {errors.widget_colors?.font_color.message}
              </Typography>
            )}
          </div>
          <div className={styles['set-color']}>
            <Typography className={clsx('color-#0B1641', 'text-14', styles['color-title'])}>
              Цвет ссылок
            </Typography>
            <ColorPicker componentName="widget_colors" colorName="link_color" />
            {errors.widget_colors?.link_color && (
              <Typography className={clsx('text-14', styles['color-error'])}>
                {errors.widget_colors?.link_color.message}
              </Typography>
            )}
          </div>
        </div>
        <div className={styles['widget-wrapper']}>
          <Typography className={clsx('text-14', styles.preview)}>Превью:</Typography>
          <div className={styles.widget}>
            <Typography className={styles['widget-title']}>
              Авторизуйтесь пользователем Trusted
            </Typography>
            <div className={styles.divider} />
            <div className={styles['title-container']}>
              {showAvatar && avatarSrc && (
                <img
                  src={`${(avatarSrc?.startsWith('data:') ? '' : BACKEND_URL + '/') + avatarSrc}`}
                  width="40px"
                  height="40px"
                  style={{ margin: '0 10px' }}
                />
              )}
              <Typography className={clsx(styles['widget-subtitle'], 'color-0B1641')}>
                {replaceJSX(
                  widgetTitle,
                  'WIDGET_APP_NAME',
                  <span style={{ color: buttonColor }}>{WIDGET_APP_NAME}</span>,
                )}
              </Typography>
            </div>
            <TextField
              label="Введите ID или логин"
              variant="standard"
              InputProps={{
                classes: {
                  underline: styles['input-underline'],
                  focused: styles['widget-input-label-focused'],
                },
              }}
              InputLabelProps={{
                className: styles['widget-input-label'],
                classes: {
                  focused: styles['widget-input-label-focused'],
                  asterisk: styles['disable-asterisk'],
                },
              }}
              fullWidth
              className={styles['text-field']}
            />
            <div className={styles['widget-buttons']}>
              <Button style={{ color: buttonColor }} variant="custom2">
                СОЗДАТЬ АККАУНТ
              </Button>
              <Button style={{ background: buttonColor, color: fontColor }} variant="custom">
                ДАЛЕЕ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
