import React, { FC, MouseEvent, useState } from 'react';
import { ResizablePanel } from './ResizablePanel';
import styles from './ApplicationUserPanel.module.css';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { TUser } from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { TAppSlice } from '../../redux/appSlice';
import { getImageURL, getMonthByNumber, isOwnerOrEditor } from '../../helpers';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as OwnerIcon } from '../../icons/Owner.svg';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as GoOverIcon } from '../../icons/GoOver.svg';
import { ReactComponent as CakeIcon } from '../../icons/Cake.svg';
import { ReactComponent as MailIcon } from '../../icons/Mail.svg';
import { ReactComponent as PhoneIcon } from '../../icons/Phone.svg';
import { connect } from 'react-redux';
import { IconWrapper } from '../IconWrapper';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { Link } from 'react-router-dom';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';

type TApplicationUserPanel = {
  client?: string;
  user: { user: Partial<TUser>; role: string } | null;
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  isMobile: TAppSlice['isMobile'];
  loggedUserId?: string;
};

const mapStateToProps = (state: RootState) => ({
  isClientPanelOpen: state.app.isClientPanelOpen,
  isMobile: state.app.isMobile,
  loggedUserId: state.user.userProfile.id,
});

const ApplicationUserPanelComponent: FC<TApplicationUserPanel> = ({
  client,
  user,
  isClientPanelOpen,
  isMobile,
  loggedUserId,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);
  const hidden = isMobile || isClientPanelOpen;
  const date = new Date(user?.user.birthdate || 0);
  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const handleClosePopover = () => setAnchorEl(null);
  return (
    <ResizablePanel>
      <div className={clsx(styles.panel, { [styles['panel-hidden']]: hidden })}>
        <div className={styles['panel-top']}>
          <div
            className={styles['app-icon-wrapper']}
            style={{
              backgroundImage: `url${getImageURL(user?.user?.picture)}`,
            }}
          >
            {!user?.user.picture && (
              <div className={styles['app-icon-default']}>
                {user?.user.nickname
                  ?.split(' ')
                  .map((name: string) => name[0]?.toUpperCase())
                  .join('')}
              </div>
            )}
          </div>
          <div className={styles['name-wrapper']}>
            <Typography
              className={clsx(
                'header-2-medium',
                'font-golos',
                'color-0B1641',
                styles['overflow-ellipsis'],
                styles['app-name'],
              )}
              component="div"
            >
              {(user?.user.nickname || '').trim()
                ? user?.user.nickname
                : ((user?.user.given_name || '') + ' ' + (user?.user.family_name || '')).trim() ||
                  'Нет имени'}
            </Typography>
            <Popover
              classes={{
                paper: styles.paper,
              }}
              PaperProps={{
                sx: {
                  transform: `translateX(${
                    290 - (anchorEl?.offsetWidth || 290)
                  }px) translateY(12px) !important`,
                },
              }}
              onClose={handleClosePopover}
              anchorEl={anchorEl}
              open={isPopoverOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Link
                style={{ display: 'block', width: '100%', textDecoration: 'none' }}
                to={{
                  pathname:
                    Number(loggedUserId) === user?.user?.id
                      ? '/profile'
                      : `/application/user/${client}/${user?.user?.id}`,
                  state: { prevPath: location.pathname },
                }}
              >
                <CustomPopoverButton
                  startIcon={<AvatarIcon className={styles['action-button-icon']} />}
                >
                  Посмотреть профиль
                </CustomPopoverButton>
              </Link>
              <CustomPopoverButton
                startIcon={<OwnerIcon className={styles['action-button-icon']} />}
              >
                Изменить полномочия
              </CustomPopoverButton>
              <CustomPopoverButton
                startIcon={<QuitIcon className={styles['action-button-icon']} />}
                onClick={async () => {
                  await deleteAllSession(user?.user.id);
                  window.location.reload();
                }}
              >
                Завершить все сеансы
              </CustomPopoverButton>
            </Popover>
            <Button
              onClick={handleOpenPopover}
              className={clsx('color-0B1641', 'text-14', styles['popover-button'])}
              variant="custom"
              color="secondary"
              endIcon={<ArrowDown fill="#0B1641" />}
            >
              {isOwnerOrEditor(user?.role) ? 'Администратор' : 'Участник'}
            </Button>
          </div>
          <div className={styles['go-over-wrapper']}>
            <Link
              style={{ display: 'block', width: '100%', textDecoration: 'none' }}
              to={{
                pathname:
                  Number(loggedUserId) === user?.user?.id
                    ? '/profile'
                    : `/application/user/${client}/${user?.user?.id}`,
                state: { prevPath: location.pathname },
              }}
            >
              <GoOverIcon className={styles['action-button-icon']} />
            </Link>
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles['flex-container']}>
            <IconWrapper>
              <CakeIcon className={styles['info-icon']} />
            </IconWrapper>
            <Typography className={clsx('text-14', 'color-0B1641', styles['info-item'])}>
              {`0${date.getDate()}`.slice(-2)} {getMonthByNumber(date.getMonth())}{' '}
              {date.getFullYear()} г.
            </Typography>
          </div>
          {user?.user.email && (
            <div className={styles['flex-container']}>
              <IconWrapper>
                <MailIcon className={styles['info-icon']} />
              </IconWrapper>
              <Typography className={clsx('text-14', 'color-0B1641', styles['info-item'])}>
                {user.user.email}
              </Typography>
            </div>
          )}
          {user?.user.phone_number && (
            <div className={styles['flex-container']}>
              <IconWrapper>
                <PhoneIcon className={styles['info-icon']} />
              </IconWrapper>
              <Typography className={clsx('text-14', 'color-0B1641', styles['info-item'])}>
                {user.user.phone_number}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </ResizablePanel>
  );
};

export const ApplicationUserPanel = connect(mapStateToProps)(ApplicationUserPanelComponent);
