import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React, { FC } from 'react';
import { ReactComponent as MenuTopBar } from '../icons/MenuTopBar.svg';
import { ReactComponent as SearchIcon } from '../icons/Search.svg';
import { ReactComponent as Exit } from '../icons/Exit.svg';
import styles from './TopBar.module.css';
import { Bell } from './Bell';
import { connect, useDispatch } from 'react-redux';
import { setIsMenuOpen, TAppSlice } from '../redux/appSlice';
import { RootState } from '../redux/store';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { TUserProfile } from '../redux/userSlice';
import { sendLogoutRequest } from '../requests/oidc';
import { LOGO_URL, PROJECT_NAME } from '../constants';
import Tooltip from '@mui/material/Tooltip';

const mapStateToProps = (state: RootState) => ({
  isMenuOpen: state.app.isMenuOpen,
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  deleted: state.user.userProfile.deleted,
  birthdate: state.user.userProfile.birthdate,
});

type TTopBar = {
  isMenuOpen: TAppSlice['isMenuOpen'];
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  deleted: TUserProfile['deleted'];
  birthdate: TUserProfile['birthdate'];
};

export const TopBarComponent: FC<TTopBar> = ({
  isMenuOpen,
  userId,
  email,
  passwordChangeRequired,
  birthdate,
  deleted,
}) => {
  const disableMenu = userId && (passwordChangeRequired || !email || !birthdate || deleted);
  const dispatch = useDispatch();

  const onMenuIconClick = () => {
    if (!disableMenu) dispatch(setIsMenuOpen(!isMenuOpen));
  };
  return (
    <div className={styles['top-bar']}>
      {!disableMenu && (
        <Tooltip
          arrow
          title={'Меню'}
          classes={{
            tooltip: styles['tooltip'],
            arrow: styles['tooltip-arrow'],
          }}
        >
          <IconButton onClick={onMenuIconClick} className={styles['menu-icon']}>
            <MenuTopBar />
          </IconButton>
        </Tooltip>
      )}
      <div className={styles.logo}>
        <img className={styles['logo-icon']} src={LOGO_URL} />
        <span className={clsx('font-basically', styles['logo-text'])}>{PROJECT_NAME}</span>
      </div>
      {!disableMenu && (
        <>
          <TextField
            className={clsx(styles.search, 'custom')}
            variant="standard"
            placeholder="Поиск"
            InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
            inputProps={{ className: styles.input }}
          />
          <Bell className={styles['bell-icon']} />
        </>
      )}
      {disableMenu && (
        <Button
          onClick={sendLogoutRequest}
          startIcon={<Exit className={styles['start-icon']} />}
          className={clsx('text-15-regular', 'color-0B1641', styles['exit-button'])}
        >
          Выход
        </Button>
      )}
    </div>
  );
};

export const TopBar = connect(mapStateToProps)(TopBarComponent);
