import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './LdapColor.module.css';
import ColorPicker from './ColorPicker';
import { EditLdapProviderInputs } from './EditLdapProvider';
import { useFormContext, useWatch } from 'react-hook-form';
import { replaceJSX } from '../helpers';
import { BACKEND_URL } from '../constants';

export type TLDAPColors = { button_color_ldap: string; font_color_ldap: string };

type TLdapColor = {
  avatarSrc: string | null;
  providerName?: string;
};

export const LdapColor: FC<TLdapColor> = ({ providerName, avatarSrc }) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<EditLdapProviderInputs>();
  const buttonColor = useWatch({ control, name: 'ldap_colors.button_color_ldap' });
  const fontColor = useWatch({ control, name: 'ldap_colors.font_color_ldap' });
  const ldapTitle = useWatch({ control, name: 'ldap_title' });
  const showAvatar = useWatch({ control, name: 'show_ldap_avatar' });

  return (
    <div className={styles['ldap-color']}>
      <Typography className={clsx('color-#0B1641', 'text-14', styles['color-title'])}>
        Заголовок LDAP
      </Typography>
      <TextField
        {...register('ldap_title')}
        className="custom"
        fullWidth
        variant="standard"
        style={{ marginBottom: 32 }}
      />
      <div className={styles['flex-container']}>
        <div className={styles['set-colors-container']}>
          <div className={styles['set-color']}>
            <Typography className={clsx('color-#0B1641', 'text-14', styles['color-title'])}>
              Цвет кнопок
            </Typography>
            <ColorPicker componentName="ldap_colors" colorName="button_color_ldap" />
            {errors.ldap_colors?.button_color_ldap && (
              <Typography className={clsx('text-14', styles['color-error'])}>
                {errors.ldap_colors?.button_color_ldap.message}
              </Typography>
            )}
          </div>
          <div className={styles['set-color']}>
            <Typography className={clsx('color-#0B1641', 'text-14', styles['color-title'])}>
              Цвет шрифта на кнопках
            </Typography>
            <ColorPicker componentName="ldap_colors" colorName="font_color_ldap" />
            {errors.ldap_colors?.font_color_ldap && (
              <Typography className={clsx('text-14', styles['color-error'])}>
                {errors.ldap_colors?.font_color_ldap.message}
              </Typography>
            )}
          </div>
        </div>
        <div className={styles['ldap-wrapper']}>
          <Typography className={clsx('text-14', styles.preview)}>Превью:</Typography>
          <div className={styles.ldap}>
            <Typography className={styles['ldap-title']}>
              Авторизуйтесь пользователем Trusted
            </Typography>
            <div className={styles.divider} />
            <div className={styles['title-container']}>
              {showAvatar && avatarSrc && (
                <img
                  src={`${(avatarSrc?.startsWith('data:') ? '' : BACKEND_URL + '/') + avatarSrc}`}
                  width="40px"
                  height="40px"
                  style={{ margin: '0 10px' }}
                />
              )}
              <Typography className={clsx(styles['ldap-subtitle'], 'color-0B1641')}>
                {replaceJSX(
                  ldapTitle || '',
                  'LDAP_PROVIDER_TITLE',
                  <span style={{ color: buttonColor }}>{providerName}</span>,
                )}
              </Typography>
            </div>
            <TextField
              label="Логин"
              variant="standard"
              InputProps={{
                classes: {
                  underline: styles['input-underline'],
                  focused: styles['ldap-input-label-focused'],
                },
              }}
              InputLabelProps={{
                className: styles['ldap-input-label'],
                classes: {
                  focused: styles['ldap-input-label-focused'],
                  asterisk: styles['disable-asterisk'],
                },
              }}
              fullWidth
              className={styles['text-field']}
            />
            <TextField
              label="Пароль"
              variant="standard"
              InputProps={{
                classes: {
                  underline: styles['input-underline'],
                  focused: styles['ldap-input-label-focused'],
                },
              }}
              InputLabelProps={{
                className: styles['ldap-input-label'],
                classes: {
                  focused: styles['ldap-input-label-focused'],
                  asterisk: styles['disable-asterisk'],
                },
              }}
              fullWidth
              className={styles['text-field']}
              type="password"
            />
            <div className={styles['ldap-buttons']}>
              <Button style={{ color: buttonColor }} variant="custom2">
                СМЕНИТЬ ПАРОЛЬ
              </Button>
              <Button style={{ background: buttonColor, color: fontColor }} variant="custom">
                ВОЙТИ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
