import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CopyIcon } from '../../icons/Copy.svg';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useEffect,
  useState,
  // #371 KeyboardEvent,
  useRef,
} from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import { SubmitHandler, useFieldArray, useForm, useWatch, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TApplication,
  TClient,
  useUpdateApplicationMutation,
  useGetApplicationByIdQuery,
  clientApi,
} from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { WidgetColor } from '../WidgetColor';
import { IconWrapper } from '../IconWrapper';
import { setApplicationFormChanged } from '../../redux/appSlice';
import { CustomRadioButton } from '../CustomRadioButton';
import { AddProvider } from '../AddProvider';
import {
  MiscProviderType,
  TOauthProvider,
  useGetProvidersQuery,
  useDeactivateProvidersMutation,
  TMiscProvider,
} from '../../redux/services/provider';
import Skeleton from '@mui/material/Skeleton';
import { DescriptionField, RedirectUrisField } from './EditApplicationFields';
import { UploadAndDisplayImage } from '../UploadAndDisplayImage';
import { /* #371 getDeclinationByNumber,*/ getProviderTitleByType, isObjectEmpty } from '../../helpers';
import { /* #371 ACCESS_TOKEN_TTL,*/ BACKEND_URL } from '../../constants';
import { PasswordTextfield } from '../custom/PasswordTextfield';

export type TWidgetColors = { button_color: string; link_color: string; font_color: string };

export type TEditAppicationInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  // #371 refresh_token_ttl: string;
  // #371 access_token_ttl: string;
  avatar: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  widget_title: string;
};

const redirectUriSchema = yup.object({
  value: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .matches(/(^https)/, {
      excludeEmptyString: true,
      message: 'Адрес должен начинаться с https://',
    }),
});

const logoutUriSchema = yup.object({
  value: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Ссылка не может превышать 2000 символов'),
});

const schema = yup
  .object({
    name: yup
      .string()
      .max(50, 'Название не может превышать 50 символов')
      .required('Обязательное поле'),
    description: yup.string().max(255),
    domain: yup
      .string()
      .url('Неверный формат ссылки')
      .max(2000, 'Ссылка не может превышать 2000 символов')
      .required('Обязательное поле'),
    redirect_uris: yup.array().of(redirectUriSchema).required(),
    post_logout_redirect_uris: yup.array().of(logoutUriSchema).required(),
    // #371
    // refresh_token_ttl: yup
    //   .number()
    //   .min(1800, 'Время жизни токена обновления не может быть меньше 1800 секунд')
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    // access_token_ttl: yup
    //   .number()
    //   .min(60, 'Время жизни токена доступа не может быть меньше 60 секунд')
    //   .max(
    //     ACCESS_TOKEN_TTL,
    //     `Время жизни токена доступа не может превышать ${ACCESS_TOKEN_TTL} секунд` +
    //       (ACCESS_TOKEN_TTL / 60 / 60 / 24 >= 1
    //         ? ` (${
    //             ACCESS_TOKEN_TTL / 60 / 60 / 24 +
    //             ' ' +
    //             getDeclinationByNumber(ACCESS_TOKEN_TTL / 60 / 60 / 24, ['день', 'дня', 'дней'])
    //           }).`
    //         : ''),
    //   )
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
  })
  .required();

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  userRole: state.user.userProfile.role,
});

const EditComponentWrapper: FC<{ userId?: string; userRole?: string }> = ({ userId }) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { data: selectedClient } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: clientId || '' },
    {
      skip: !userId || !clientId,
    },
  );
  return (
    <>
      {selectedClient && userId ? (
        <EditApplicationComponent
          selectedClient={selectedClient.client}
          role={selectedClient.role}
          userId={userId}
        />
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

type TEditApplicationComponent = {
  selectedClient: TClient;
  role: TApplication['role'];
  userId: string;
};

const EditApplicationComponent: FC<TEditApplicationComponent> = ({
  selectedClient,
  role,
  userId,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  // #371 const [showRefreshTokenInput, setShowRefreshTokenInput] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<
    TOauthProvider | TMiscProvider | undefined
  >(undefined);
  const { data: providers, isLoading: providersLoading } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    onlyActive: false,
  });

  const savedCallback = useRef<() => void>();

  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [updateApplication, result] = useUpdateApplicationMutation();

  useEffect(() => {
    if (selectedClient) {
      setIsVisible(selectedClient.is_visible);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (result.isSuccess) history.push('/applications');
  }, [result]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  const methods = useForm<TEditAppicationInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient?.redirect_uris.map((uri) => ({ value: uri })) || [
        { value: '' },
      ],
      post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris.map((uri) => ({
        value: uri,
      })) || [{ value: '' }],
      avatar: selectedClient?.avatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    trigger,
    getValues,
  } = methods;

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field)) return field.some((elem) => elem.value);
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const [deactivateProvider] = useDeactivateProvidersMutation();

  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const closeSaveModal = () => setSaveModalOpen(false);

  const handleProviderClick = (provider: TOauthProvider | TMiscProvider) => {
    if (
      provider.type !== MiscProviderType.CREDENTIALS &&
      provider.type !== MiscProviderType.EMAIL
    ) {
      setSelectedProvider(provider);
      setProviderModalOpen(true);
    }
  };

  const showAvatar = useWatch({ control, name: 'show_avatar_in_widget' });
  const {
    fields: redirectUris,
    append: redirectAppend,
    remove: redirectRemove,
  } = useFieldArray({
    control,
    name: 'redirect_uris',
  });
  const {
    fields: logoutUris,
    append: logoutAppend,
    remove: logoutRemove,
  } = useFieldArray({
    control,
    name: 'post_logout_redirect_uris',
  });

  const onSubmit: SubmitHandler<TEditAppicationInputs> = async (data) => {
    if (data.redirect_uris.every((uri) => !uri.value)) {
      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;
    const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
      (acc: Partial<Omit<TApplication['client'], 'avatar'> & { avatar: File | null }>, field) => {
        if (field === 'post_logout_redirect_uris' || field === 'redirect_uris') {
          acc[field] = data[field].reduce((dataAcc: string[], uri) => {
            if (uri.value) dataAcc.push(uri.value);
            return dataAcc;
          }, []);
        } else if (field === 'avatar') {
          if (typeof data.avatar !== 'string') acc.avatar = data.avatar;
        } else if (field === 'widget_colors') acc.widget_colors = data.widget_colors;
        else if (field === 'show_avatar_in_widget')
          acc.show_avatar_in_widget = data.show_avatar_in_widget;
        else acc[field] = data[field];
        return acc;
      },
      {},
    );

    await updateApplication({
      ...payload,
      client_id: selectedClient?.client_id,
      grant_types: ['authorization_code', 'refresh_token'],
      registration_access_token: selectedClient?.registration_access_token,
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => {
          if (applicationFormChanged) {
            savedCallback.current = () => history.push('/applications');
            return setSaveModalOpen(true);
          }
          history.push('/applications');
        }}
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Приложения
      </Button>
      <form className={styles['create-client-form']} onSubmit={handleSubmit(onSubmit)}>
        <Typography className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}>
          Настройки приложения
        </Typography>
        <Typography
          className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
        >
          Основная информация
        </Typography>
        <Typography
          className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
        >
          Название приложения
        </Typography>
        <TextField
          {...register('name', {
            required: true,
            onBlur: (event: FocusEvent<HTMLInputElement>) => {
              setValue('name', event.target.value.trim());
            },
            onChange: () => {
              if (errors.name) clearErrors('name');
            },
          })}
          className="custom"
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ''}
          fullWidth
          variant="standard"
        />
        <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
          Имя приложения, отображаемое пользователям
        </Typography>
        <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
          Описание приложения
        </Typography>
        <TextField
          {...register('description', {
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length > 255) {
                setError('description', {
                  message: 'Невозможно ввести более 255 символов',
                  type: 'validate',
                });
                setValue('description', event.target.value.slice(0, 255));
              } else if (errors.description) {
                clearErrors('description');
              }
            },
          })}
          className="custom"
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : ''}
          fullWidth
          variant="standard"
          multiline
        />
        <DescriptionField control={control} />
        <div className={styles['upload-file']}>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Обложка приложения
          </Typography>
          <UploadAndDisplayImage
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            defaultValue={selectedClient?.avatar || null}
            componentName={'edit-client'}
            setAvatarValue={setAvatarValue}
            DefaultIcon={<AppIcon fill="#ced0d9" />}
          />
          {errors.avatar && (
            <Typography className={clsx('text-14', styles['input-error'])}>
              {errors.avatar.message}
            </Typography>
          )}
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
          </Typography>
        </div>
        <div className={styles.divider} />
        <Typography
          className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
        >
          Параметры приложения
        </Typography>
        <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
          Идентификатор (Client_id)
        </Typography>
        <TextField
          value={selectedClient?.client_id}
          disabled
          className="custom"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  arrow
                  title="Копировать"
                  classes={{
                    tooltip: styles['input-tooltip'],
                    arrow: styles['input-tooltip-arrow'],
                  }}
                >
                  <Button
                    className={styles['input-adornment-button']}
                    onClick={() => {
                      if (selectedClient) navigator.clipboard.writeText(selectedClient.client_id);
                    }}
                  >
                    <CopyIcon />
                  </Button>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          fullWidth
          variant="standard"
        />
        <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
          Уникальный идентификатор приложения
        </Typography>
        <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
          Секретный ключ (client_secret)
        </Typography>
        <PasswordTextfield
          value={selectedClient?.client_secret}
          disabled
          className="custom"
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          fullWidth
          variant="standard"
          id="copy"
        />
        <Button variant="custom2" className={clsx('text-14', styles['input-subtitle'])}>
          Обновить
        </Button>
        <Typography
          className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
        >
          Адрес приложения
        </Typography>
        <TextField
          {...register('domain', {
            onChange: () => {
              if (errors.domain) clearErrors('domain');
            },
          })}
          className="custom"
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          error={!!errors.domain}
          helperText={errors.domain ? errors.domain.message : ''}
          fullWidth
          variant="standard"
        />
        <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
          Адрес приложения в формате «протокол://доменное имя:порт»
        </Typography>
        <>
          {redirectUris.map((uri, index) => {
            return (
              <div key={uri.id}>
                <Typography
                  className={clsx(
                    'text-14',
                    'color-0B1641',
                    styles.asterisk,
                    styles['input-title'],
                  )}
                >
                  Возвратный URL #{index + 1} (Redirect_uri)
                </Typography>
                <div className={styles['field-item']}>
                  <TextField
                    {...register(`redirect_uris.${index}.value`, {
                      onChange: () => {
                        if (errors.redirect_uris?.[index])
                          clearErrors(`redirect_uris.${index}.value`);
                      },
                    })}
                    className={clsx('custom', styles['add-text-field'])}
                    FormHelperTextProps={{
                      className: clsx('text-14', 'color-858BA0'),
                    }}
                    onBlur={() => {
                      if (getValues('redirect_uris').every((uri) => !uri.value))
                        setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
                      else {
                        clearErrors(`redirect_uris.0.value`);
                        trigger(`redirect_uris.${index}.value`);
                      }
                    }}
                    variant="standard"
                    error={!!errors.redirect_uris}
                    helperText={
                      errors.redirect_uris ? errors?.redirect_uris?.[index]?.value?.message : ''
                    }
                  />

                  {redirectUris.length > 1 ? (
                    <Button
                      variant="custom"
                      color="secondary"
                      onClick={() => redirectRemove(index)}
                      className={clsx(styles['delete-button'])}
                    >
                      Удалить
                    </Button>
                  ) : (
                    <RedirectUrisField
                      control={control}
                      onClick={() => redirectAppend({ value: '', name: '' })}
                      className={styles['add-button']}
                      name="redirect_uris"
                    />
                  )}
                </div>
                {index === 0 && (
                  <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
                    Адрес, на который пользователь переадресовывается после авторизации
                  </Typography>
                )}
              </div>
            );
          })}
          {redirectUris.length > 1 && (
            <RedirectUrisField
              control={control}
              onClick={() => redirectAppend({ value: '', name: '' })}
              className={clsx(styles['add-button'], styles['add-button-bottom'])}
              name="redirect_uris"
            />
          )}
        </>
        <>
          {logoutUris.map((uri, index) => {
            return (
              <div key={uri.id}>
                <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                  URL выхода из системы #{index + 1} (post_logout_redirect_uri)
                </Typography>
                <div className={styles['field-item']}>
                  <TextField
                    {...register(`post_logout_redirect_uris.${index}.value`, {
                      onChange: () => {
                        if (errors.post_logout_redirect_uris?.[index])
                          clearErrors(`post_logout_redirect_uris.${index}.value`);
                      },
                    })}
                    className={clsx('custom', styles['add-text-field'])}
                    FormHelperTextProps={{
                      className: clsx('text-14', 'color-858BA0', styles['helper-text']),
                    }}
                    error={!!errors.post_logout_redirect_uris}
                    helperText={
                      errors.post_logout_redirect_uris
                        ? errors?.post_logout_redirect_uris?.[index]?.value?.message
                        : ''
                    }
                    variant="standard"
                  />
                  {logoutUris.length > 1 ? (
                    <Button
                      variant="custom"
                      color="secondary"
                      onClick={() => logoutRemove(index)}
                      className={clsx(styles['delete-button'])}
                    >
                      Удалить
                    </Button>
                  ) : (
                    <RedirectUrisField
                      control={control}
                      onClick={() => logoutAppend({ value: '', name: '' })}
                      className={styles['add-button']}
                      name="post_logout_redirect_uris"
                    />
                  )}
                </div>
                {index === 0 && (
                  <Typography
                    className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}
                    style={{ width: '85%' }}
                  >
                    Адрес, на который переадресовывается пользователь после выхода. Если значение не
                    указано, то используется «Возвратный URL»
                  </Typography>
                )}
              </div>
            );
          })}
          {logoutUris.length > 1 && (
            <RedirectUrisField
              control={control}
              onClick={() => logoutAppend({ value: '', name: '' })}
              name="post_logout_redirect_uris"
            />
          )}
        </>
        {/* #371
        <div className={styles['switch-wrapper']}>
          <Typography className={clsx('text-14', 'color-0B1641')}>
            Ограничить время действия токена обновления (refresh_token)
          </Typography>
          <Switch
            value={showRefreshTokenInput}
            onChange={() => setShowRefreshTokenInput((shown) => !shown)}
            disableRipple
          />
        </div>

        {showRefreshTokenInput && (
          <>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Время действия токена обновления (refresh_token)
            </Typography>
            <div style={{ position: 'relative' }}>
              <TextField
                {...register('refresh_token_ttl', {
                  onChange: () => {
                    if (errors.refresh_token_ttl) clearErrors('refresh_token_ttl');
                  },
                })}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key.toLowerCase() === 'e') e.preventDefault();
                }}
                type="number"
                inputProps={{ className: styles.input }}
                className={clsx('custom', styles['token-textfield'])}
                InputProps={{
                  className: styles['token-input'],
                }}
                FormHelperTextProps={{
                  className: clsx('text-14', 'color-858BA0'),
                }}
                error={!!errors.refresh_token_ttl}
                helperText={errors.refresh_token_ttl ? errors.refresh_token_ttl.message : ''}
                fullWidth
                variant="standard"
              />
              <Typography
                className={clsx('text-14', 'color-858BA0')}
                style={{ position: 'absolute', right: 40, top: 10 }}
              >
                секунд
              </Typography>
            </div>
          </>
        )}
        <Typography
          className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
        >
          Время действия авторизационного токена (access_token)
        </Typography>
        <div style={{ position: 'relative' }}>
          <TextField
            {...register('access_token_ttl', {
              onChange: () => {
                if (errors.access_token_ttl) clearErrors('access_token_ttl');
              },
            })}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key.toLowerCase() === 'e') e.preventDefault();
            }}
            type="number"
            className={clsx('custom', styles['token-textfield'])}
            InputProps={{
              className: styles['token-input'],
            }}
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0', styles['helper-token']),
            }}
            fullWidth
            variant="standard"
          />
          <Typography
            className={clsx('text-14', 'color-858BA0')}
            style={{ position: 'absolute', right: 40, top: 10 }}
          >
            секунд
          </Typography>
        </div>
        {!!errors.access_token_ttl && (
          <Typography className={clsx('text-14', styles['input-error'])}>
            {errors.access_token_ttl.message}
          </Typography>
        )} */}
        <div className={styles.divider} />
        <Typography
          className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
        >
          Способы входа
        </Typography>
        <Button
          variant="custom2"
          className={styles['add-button']}
          startIcon={<AddIcon className={styles['add-icon']} />}
          onClick={() => setProviderModalOpen(true)}
        >
          Добавить способ входа
        </Button>
        <div className={styles.providers}>
          {providersLoading &&
            [null, null].map((_, index) => (
              <div key={index} className={styles.provider}>
                <IconWrapper>
                  <Skeleton width={20} height={30} />
                </IconWrapper>
                <div>
                  <Typography className={clsx('text-14', 'color-0B1641')}>
                    <Skeleton />
                  </Typography>
                  <Typography className={clsx('text-12', 'color-858BA0')}>OAuth 2</Typography>
                </div>
                <IconButton className={styles['icon-button-wrapper']}>
                  <CloseIcon className={styles['icon-button']} />
                </IconButton>
              </div>
            ))}
          {providers
            ?.filter((provider) => provider.is_active)
            .map((provider) => (
              <div
                key={provider.id + provider.type}
                className={styles.provider}
                onClick={() => handleProviderClick(provider)}
              >
                <div
                  style={{
                    backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                  }}
                  className={styles['provider-icon-wrapper']}
                >
                  {!provider.avatar && <IdIcon />}
                </div>
                <div className={styles['provider-name-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                    {provider.name}
                  </Typography>
                  <Typography className={clsx('text-12', 'color-858BA0')}>
                    {getProviderTitleByType(provider.type)}
                  </Typography>
                </div>
                {providers?.filter(
                  (provider) =>
                    provider.is_active && provider.type !== MiscProviderType.CREDENTIALS,
                ).length >= 1 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      deactivateProvider({
                        body: {
                          providers: [
                            {
                              type: provider.type,
                              id: String(provider.id),
                              client_id: provider.client_id,
                            },
                          ],
                        },
                        client_id: selectedClient.client_id,
                      });
                    }}
                    className={styles['icon-button-wrapper']}
                  >
                    <CloseIcon className={styles['icon-button']} />
                  </IconButton>
                )}
              </div>
            ))}
        </div>
        <div className={styles.divider} />
        <Typography
          className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
        >
          Публичность
        </Typography>
        <div className={styles['custom-checkbox-wrapper']}>
          <CustomRadioButton
            selected={!isVisible}
            onClick={() => {
              setIsVisible(false);
            }}
          />
          <div>
            <Typography className={clsx('text-14', 'color-0B1641')}>Скрытое приложение</Typography>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              Приложение нельзя найти в поиске
            </Typography>
          </div>
        </div>
        <div className={styles['custom-checkbox-wrapper']}>
          <CustomRadioButton
            selected={isVisible}
            onClick={() => {
              setIsVisible(true);
            }}
          />
          <div>
            <Typography className={clsx('text-14', 'color-0B1641')}>Видимое приложение</Typography>
            <Typography className={clsx('text-12', 'color-858BA0', styles['input-subtitle'])}>
              Приложение можно найти в поиске
            </Typography>
          </div>
        </div>
        <div className={styles.divider} />
        <Typography
          className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
        >
          Внешний вид виджета
        </Typography>
        <div className={styles['switch-wrapper']}>
          <Typography className={clsx('text-14', 'color-0B1641')}>
            Показывать обложку приложения на виджете
          </Typography>
          <Switch
            checked={showAvatar}
            {...register('show_avatar_in_widget', {
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                setValue('show_avatar_in_widget', event.target.checked, { shouldDirty: true });
              },
            })}
          />
        </div>
        <FormProvider {...methods}>
          <WidgetColor name={getValues('name')} avatarSrc={avatarSrc} showAvatar={showAvatar} />
        </FormProvider>
        <div className={styles['submit-buttons']}>
          <Button
            onClick={() => {
              if (applicationFormChanged) {
                savedCallback.current = () => history.goBack();
                return setSaveModalOpen(true);
              }
              history.goBack();
            }}
            variant="custom"
            color="secondary"
          >
            Отмена
          </Button>
          <Button className={styles['create-button']} type="submit" variant="custom">
            Сохранить
          </Button>
        </div>
      </form>
      <AddProvider
        role={role}
        close={() => setProviderModalOpen(false)}
        clearSelectedProvider={() => setSelectedProvider(undefined)}
        isOpen={providerModalOpen}
        selectedProvider={selectedProvider}
      />
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                savedCallback.current?.();
                dispatch(setApplicationFormChanged(false));
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const EditApplication = connect(mapStateToProps)(EditComponentWrapper);
