import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as BellIcon } from '../icons/Bell.svg';
import styles from './Bell.module.css';
import { useSnackbar } from 'notistack';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { setIsNotificationPanelOpen, TAppSlice } from '../redux/appSlice';
import { TNoticeSlice } from '../redux/noticesSlice';
import Tooltip from '@mui/material/Tooltip';

type TBell = {
  className: string;
  isNotificationPanelOpen: TAppSlice['isNotificationPanelOpen'];
  notices?: Array<TNoticeSlice>;
};

const mapStateToProps = (state: RootState) => ({
  isNotificationPanelOpen: state.app.isNotificationPanelOpen,
  notices: state.notices,
});

const BellComponent: React.FC<TBell> = ({ className, isNotificationPanelOpen, notices }) => {
  const newNotices = notices?.filter((notice) => notice.isRead === false);
  const { closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const onPanelIconClick = () => {
    dispatch(setIsNotificationPanelOpen(!isNotificationPanelOpen));
    closeSnackbar();
  };

  return (
    <div className={clsx(className, styles.bell)}>
      <Tooltip
        arrow
        title={'Уведомления'}
        classes={{
          tooltip: styles['tooltip'],
          arrow: styles['tooltip-arrow'],
        }}
      >
        <IconButton onClick={onPanelIconClick}>
          {newNotices?.length ? (
            <div className={styles.count}>{newNotices.length}</div>
          ) : (
            ''
          )}
          <BellIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const Bell = connect(mapStateToProps)(BellComponent);