import React, { useState } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { getAccessToken } from '../../service/auth';
import { checkAccessToken } from '../../requests/oidc';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserRoleInApp, TUserProfile } from '../../redux/userSlice';
import { isEditor, isOwner } from '../../helpers';
import { getUserRoleInApp } from '../../requests/user';

type OwnerOrEditorRouteProps = RouteProps & {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  userRole?: string;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  birthdate: state.user.userProfile.birthdate,
  userRole: state.user.userProfile.role,
});

const OwnerOrEditorRouteComponent: React.FC<OwnerOrEditorRouteProps> = ({
  children,
  userId,
  email,
  passwordChangeRequired,
  birthdate,
  userRole,
  ...rest
}) => {
  const [wait, setWait] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isOwnerOrEditor, setIsOwnerOrEditor] = useState(false);
  const location = useLocation();
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' &&
    userId &&
    (passwordChangeRequired || !email || !birthdate);
  const clientId = useLocation().pathname.split('/')[3];
  const dispatch = useDispatch();

  useEffect(() => {
    let cleanupFunction = false;

    const getToken = async () => {
      try {
        const token = await getAccessToken();
        if (token) {
          const checkResult = await checkAccessToken(token);
          !cleanupFunction && setIsAuthorized(checkResult);
        }
      } catch (e) {
        console.log('getToken error: ' + e);
      }
    };
    getToken();

    if (isOwner(userRole)) {
      !cleanupFunction && setIsOwnerOrEditor(true)
    } else {
      const getRole = async () => {
        if (userId) {
          const roleInApp = await getUserRoleInApp(userId || '', clientId);
          if (roleInApp && isEditor(roleInApp)) {
            dispatch(setUserRoleInApp(roleInApp));
            !cleanupFunction && setIsOwnerOrEditor(true)
          }
          !cleanupFunction && setWait(false);
        }
      }
      getRole();
    }
    
    return () => {
      cleanupFunction = true;
    };
  });

  useEffect(() => {
    if (isAuthorized && isOwnerOrEditor) {
      setWait(false);
    }
  }, [isAuthorized, isOwnerOrEditor])

  return wait ? (
    <LinearProgress />
  ) : (
    <Route
      {...rest}
      render={() => {
        if (isAuthorized && isOwnerOrEditor) {
          if (redirectToFillProfile) return <Redirect to="fill-profile" />;
          return children;
        }
        return <Redirect to={"/applications"} />;
      }}
    />
  );
};

export const OwnerOrEditorRoute = connect(mapStateToProps)(OwnerOrEditorRouteComponent);