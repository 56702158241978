import React, { useState } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { getAccessToken } from '../../service/auth';
import { checkAccessToken } from '../../requests/oidc';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TUserProfile } from '../../redux/userSlice';
import { AUTHORIZE_ONLY_ADMINS } from '../../constants';
import { isAdministrator } from '../../helpers';
import { Roles } from '../../enums';

type PrivateRouteProps = RouteProps & {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  deleted: TUserProfile['deleted'];
  role?: Roles;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  birthdate: state.user.userProfile.birthdate,
  deleted: state.user.userProfile.deleted,
  role: state.user.userProfile.role,
});

const PrivateRouteComponent: React.FC<PrivateRouteProps> = ({
  children,
  userId,
  email,
  passwordChangeRequired,
  birthdate,
  deleted,
  role,
  ...rest
}) => {
  const [wait, setWait] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' &&
    location.pathname !== '/restore-profile' &&
    userId &&
    (passwordChangeRequired || !email || !birthdate);
  const redirectToRestoreUser = location.pathname !== '/restore-profile' && userId && deleted;
  const canAuthorize = !(AUTHORIZE_ONLY_ADMINS && role && !isAdministrator(role));

  useEffect(() => {
    let cleanupFunction = false;

    const getToken = async () => {
      try {
        const token = await getAccessToken();
        if (token) {
          const checkResult = await checkAccessToken(token);
          !cleanupFunction && setIsAuthorized(checkResult);
        }
      } catch (e) {
        console.log('getToken error: ' + e);
      }

      !cleanupFunction && setWait(false);
    };
    getToken();

    return () => {
      cleanupFunction = true;
    };
  });
  return wait ? (
    <LinearProgress />
  ) : (
    <Route
      {...rest}
      render={() => {
        if (isAuthorized) {
          if (!canAuthorize)
            return (
              <Redirect to="error/Вход в личный кабинет разрешён только пользователям с ролями «владелец» и «администратор»." />
            );
          if (redirectToRestoreUser) return <Redirect to="restore-profile" />;
          if (redirectToFillProfile) return <Redirect to="fill-profile" />;
          return children;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
