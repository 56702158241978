import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { deleteNotice } from '../redux/noticesSlice';
import clsx from 'clsx';
import styles from './Notice.module.css';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as ErrorIcon } from '../icons/Error.svg';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { getMonthByNumber } from '../helpers';

type TNoticeProps = {
  id: number;
  isRead: boolean;
  message: string;
  timestamp: string;
};

export const Notice: FC<TNoticeProps> = ({ id, isRead, message, timestamp }) => {
  const getTime = () => {
    const timestampDate = new Date(timestamp);
    const time = `${String(timestampDate.getHours()).padStart(2, '0')}:${String(timestampDate.getMinutes()).padStart(2,'0',)}`;
    const timestampDay = timestampDate.getDate();
    const currentDay = new Date().getDate();

    if (timestampDay == currentDay) {
      return (
        <Typography className={clsx('text-14', 'color-858BA0')}>
          {time}
        </Typography>
      )
    } else {
      return (
        <Typography className={clsx('text-14', 'color-858BA0')}>
          {`${timestampDay} ${getMonthByNumber(timestampDate.getMonth())} в ${time}`}
        </Typography>
      )
    }
  };
  const dispatch = useDispatch();
  const removeNotice = (id: number) => dispatch(deleteNotice(id));

  return (
    <Box className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.icons}>
          <div className={styles['circle-wrapper']}>
            {!isRead && <div className={styles.circle}></div>}
          </div>
          <div className={styles.avatar}>
            <ErrorIcon />
          </div>
        </div>
          <div className={styles['message-wrapper']}>
            <Typography
              className={clsx('text-14', styles.message, {
                ['color-0B1641']: !isRead,
                ['color-858BA0']: isRead,
              })}
            >
              {message}
            </Typography>
            {timestamp && getTime()}
          </div>
          <div className={styles['button-wrapper']}>
            <IconButton
              className={clsx(styles['close-button_show'], styles['close-button'])}
              onClick={() => removeNotice(id)}
            >
              <CloseIcon />
            </IconButton>
          </div>
      </div>
    </Box>
  );
};