import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import styles from './ApplicationInfo.module.css';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as EditIcon } from '../../icons/Edit.svg';
import { ReactComponent as WithdrawIcon } from '../../icons/Withdraw.svg';
import { ReactComponent as InfoIcon } from '../../icons/Info.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as GroupIcon } from '../../icons/Group.svg';
import { ReactComponent as BasketIcon } from '../../icons/Basket.svg';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import {
  TApplication,
  TUser,
  useLazyGetScopesQuery,
  useLazyGetUserCountQuery,
  useDeleteApplicationMutation,
  useRevokeScopesMutation,
  useLazyDeleteSessionByClientQuery,
  useGetApplicationByIdQuery,
} from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { getImageURL, isOwner, isOwnerOrEditor } from '../../helpers';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Skeleton from '@mui/material/Skeleton';
import { setApplicationInfoTab, setClientPanel, TAppSlice } from '../../redux/appSlice';
import { ApplicationUserPanel } from './ApplicationUserPanel';
import { ApplicationInfoProfile } from './ApplicationInfoProfile';
import { ApplicationInfoUsers } from './ApplicationInfoUsers';
import { SelectViewType } from '../custom/SelectViewType';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { PROJECT_NAME } from '../../constants';

type TApplicationInfoComponent = {
  userId?: string;
  isMobile: TAppSlice['isMobile'];
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  isMobile: state.app.isMobile,
});
const ApplicationInfoComponent: FC<TApplicationInfoComponent> = ({ userId, isMobile }) => {
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [clientAvatar, setClientAvatar] = useState<null | string>('none');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [revokeScopes] = useRevokeScopesMutation();
  const [deleteApplication] = useDeleteApplicationMutation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const [scopesData, setScopesData] = useState<
    { scopes?: string; createdAt?: string } | undefined
  >();
  const history = useHistory();
  const dispatch = useDispatch();
  const [getScopes] = useLazyGetScopesQuery();
  const [deleteSessionByClient] = useLazyDeleteSessionByClientQuery();

  const { data: application, isLoading: isAppLoading } = useGetApplicationByIdQuery(
    { client_id: applicationId, user_id: userId || '' },
    {
      skip: !applicationId || !userId,
    },
  );

  const isLoading = isAppLoading || !userId;

  useEffect(() => {
    const setScopes = async () => {
      if (userId && applicationId)
        setScopesData((await getScopes({ userId, clientId: applicationId })).data);
    };
    setScopes();
  }, [userId, applicationId]);

  useEffect(() => {
    setClientAvatar(application?.client.avatar || null);
  }, [application]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleDeleteApp = async (clientId: string) => {
    await deleteApplication(clientId);
    history.push('/applications');
    setDeleteModalOpen(false);
  };

  const handleRevokeScopes = async () => {
    await revokeScopes({ userId, clientId: application?.client.client_id });
  };

  const handleDeleteSessionByClient = async () => {
    await deleteSessionByClient({ userId, clientId: application?.client.client_id });
    window.location.reload();
  };

  const handleClosePopover = () => setViewAnchorEl(null);
  return (
    <div className={styles.panel}>
      <Button
        onClick={() => history.push('/applications')}
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Приложения
      </Button>
      <div className={styles['panel-top']}>
        <div
          className={styles['app-icon-wrapper']}
          style={{
            backgroundImage: `url(${getImageURL(clientAvatar)})`,
          }}
        >
          {!clientAvatar && <AppIcon fill="#CED0D9" className={styles['app-icon']} />}
        </div>
        <div className={styles['name-wrapper']}>
          <Typography
            className={clsx(
              'header-2-medium',
              'font-golos',
              'color-0B1641',
              styles['overflow-ellipsis'],
            )}
            component="div"
          >
            {isLoading ? <Skeleton width={130} /> : application?.client.name}
          </Typography>
          <Popover
            classes={{
              paper: styles.paper,
            }}
            PaperProps={{
              sx: {
                transform: `translateX(${
                  290 - (viewAnchorEl?.offsetWidth || 290)
                }px) translateY(12px) !important`,
              },
            }}
            onClose={handleClosePopover}
            anchorEl={viewAnchorEl}
            open={!!viewAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <CustomPopoverButton
              onClick={() => handleDeleteSessionByClient()}
              startIcon={<QuitIcon className={styles['action-button-icon']} />}
            >
              Завершить свои сеансы
            </CustomPopoverButton>
            <Link
              style={{ display: 'block', width: '100%', textDecoration: 'none' }}
              to={`/application/${applicationId}`}
            >
              <CustomPopoverButton
                startIcon={
                  <InfoIcon
                    fill="#fff"
                    className={clsx(styles['action-button-icon'], styles['info-icon'])}
                  />
                }
                onClick={() => dispatch(setApplicationInfoTab(0))}
              >
                Посмотреть профиль
              </CustomPopoverButton>
            </Link>
            {!isOwner(application?.role) && (
              <>
                <Box className={styles.divider} />
                <CustomPopoverButton
                  startIcon={<WithdrawIcon className={styles['action-button-icon']} />}
                  style={{ marginTop: 8 }}
                  onClick={() => handleRevokeScopes()}
                >
                  Отозвать разрешения
                </CustomPopoverButton>
              </>
            )}
            {isOwnerOrEditor(application?.role) && (
              <>
                <Box className={styles.divider} />
                <CustomPopoverButton
                  startIcon={<GroupIcon className={styles['action-button-icon']} />}
                  style={{ marginTop: 8 }}
                  onClick={() => dispatch(setApplicationInfoTab(1))}
                >
                  Участники приложения
                </CustomPopoverButton>
                <Link
                  style={{ display: 'block', width: '100%', textDecoration: 'none' }}
                  to={`/applications/edit/${applicationId}`}
                >
                  <CustomPopoverButton
                    startIcon={<EditIcon className={styles['action-button-icon']} />}
                  >
                    Настройки
                  </CustomPopoverButton>
                </Link>
                <Box className={styles.divider} />
                <CustomPopoverButton
                  onClick={() => setDeleteModalOpen(true)}
                  startIcon={<BasketIcon className={styles['action-button-icon']} />}
                  style={{ marginTop: 8 }}
                >
                  Удалить
                </CustomPopoverButton>
              </>
            )}
          </Popover>
          {!isMobile && (
            <div className={styles['user-buttons']}>
              {isLoading ? (
                <Skeleton variant="rectangular" height={40} width={180} />
              ) : (
                <>
                  <Button
                    onClick={handleOpenPopover}
                    className={clsx('color-0B1641', 'text-14', styles['popover-button'])}
                    variant="custom"
                    color="secondary"
                    endIcon={<ArrowDown fill="#0B1641" />}
                  >
                    {isOwnerOrEditor(application?.role) ? 'Вы администратор' : 'Вы участник'}
                  </Button>
                  {isOwnerOrEditor(application?.role) && application && (
                    <Link
                      className={styles['edit-link']}
                      to={`/applications/edit/${application.client.client_id}`}
                    >
                      <Button
                        className={styles['edit-button']}
                        variant="custom"
                        color="secondary"
                        endIcon={<EditIcon />}
                        tabIndex={-1}
                      />
                    </Link>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className={styles['mobile-buttons']}>
          <Button
            onClick={handleOpenPopover}
            className={clsx('color-0B1641', 'text-14', styles['popover-button'])}
            variant="custom"
            color="secondary"
            endIcon={<ArrowDown fill="#0B1641" />}
          >
            {isOwnerOrEditor(application?.role) ? 'Вы администратор' : 'Вы участник'}
          </Button>
          {isOwnerOrEditor(application?.role) && application && (
            <Link
              className={styles['edit-link']}
              to={`/applications/edit/${application.client.client_id}`}
            >
              <Button
                className={styles['edit-button']}
                variant="custom"
                color="secondary"
                endIcon={<EditIcon />}
                tabIndex={-1}
              />
            </Link>
          )}
        </div>
      )}
      {isOwnerOrEditor(application?.role) ? (
        <ApplicationAdminTabs application={application} scopesData={scopesData} />
      ) : (
        <div style={{ height: '100%', overflow: 'hidden' }}>
          <ApplicationInfoProfile scopesData={scopesData} application={application} />
        </div>
      )}
      <Modal open={deleteModalOpen} onClose={closeDeleteModal}>
        <div className={styles['delete-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Удалить приложение
            </Typography>
            <IconButton onClick={closeDeleteModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Приложение {application?.client.name} будет удалено.
            <br />
            Пользователи не смогут войти в ваше приложение, используя аккаунт {PROJECT_NAME}.
            <br />
            <br />
            Это действие невозможно отменить.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeDeleteModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                if (application?.client) handleDeleteApp(application.client.client_id);
              }}
              variant="custom"
              style={{ marginLeft: 24, background: '#FC545C' }}
            >
              Удалить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

type ApplicationAdminTabsProps = {
  application?: TApplication;
  scopes?: string;
  isMobile: TAppSlice['isMobile'];
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  scopesData?: { scopes?: string; createdAt?: string };
  applicationInfoTab: number;
};

const adminTabsStateToProps = (state: RootState) => ({
  isMobile: state.app.isMobile,
  isClientPanelOpen: state.app.isClientPanelOpen,
  applicationInfoTab: state.app.applicationInfoTab,
});

const ApplicationAdminTabsComponent: FC<ApplicationAdminTabsProps> = ({
  application,
  scopesData,
  applicationInfoTab,
  isMobile,
  isClientPanelOpen,
}) => {
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const isOpen = Boolean(viewAnchorEl);
  const [selectedUser, setSelectedUser] = useState<{ user: Partial<TUser>; role: string } | null>(
    null,
  );
  const [usersCount, setUsersCount] = useState(0);
  const [getUserCount] = useLazyGetUserCountQuery();

  useEffect(() => {
    const setUsersCountOnStart = async () => {
      const { data } = await getUserCount({
        selectedAppId: application?.client.client_id || '',
        search_string: '',
      });
      setUsersCount(data?.userCount || 0);
    };
    setUsersCountOnStart();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    dispatch(setApplicationInfoTab(newValue));
  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));
  const setPanelView = (e: MouseEvent<HTMLButtonElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };

  return (
    <>
      <Tabs
        className={styles.tabs}
        TabIndicatorProps={{ className: styles['tab-indicator'] }}
        value={applicationInfoTab}
        onChange={handleChange}
      >
        <Tab
          label="Профиль"
          id="1"
          classes={{ root: styles.tab, selected: styles['tab-selected'] }}
        />
        <Tab
          label={`Участники ${usersCount}`}
          id="2"
          classes={{ root: styles.tab, selected: styles['tab-selected'] }}
        />
        {/* #293
        <Tab
          label="Приглашения"
          id="3"
          classes={{ root: styles.tab, selected: styles['tab-selected'] }}
        /> */}
        <Popover
          classes={{ paper: styles['view-popover-paper'] }}
          onClose={() => setViewAnchorEl(null)}
          anchorEl={viewAnchorEl}
          open={isOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SelectViewType isClientPanelOpen={isClientPanelOpen} setPanelView={setPanelView} />
        </Popover>
        {!isMobile && applicationInfoTab === 1 && (
          <Button
            onClick={(event: MouseEvent<HTMLButtonElement>) => setViewAnchorEl(event.currentTarget)}
            className={styles.view}
            classes={{ endIcon: styles['view-icon'] }}
            endIcon={<ArrowDown fill="#9DA2B3" />}
          >
            <Typography className={clsx('text-14', 'color-858BA0')}>Вид</Typography>
          </Button>
        )}
      </Tabs>
      <TabPanel value={applicationInfoTab} index={0}>
        <ApplicationInfoProfile application={application} scopesData={scopesData} />
      </TabPanel>
      <TabPanel value={applicationInfoTab} index={1}>
        <div style={{ position: 'relative' }}>
          <ApplicationInfoUsers
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            selectedClientId={application?.client.client_id}
          />
          <ApplicationUserPanel user={selectedUser} client={application?.client.client_id} />
        </div>
      </TabPanel>
      {/* #293
      <TabPanel value={applicationInfoTab} index={2}>
        Item Three
      </TabPanel> */}
    </>
  );
};

const ApplicationAdminTabs = connect(adminTabsStateToProps)(ApplicationAdminTabsComponent);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div style={index === 0 ? { height: '100%', overflow: 'hidden' } : {}} hidden={value !== index}>
      {value === index && children}
    </div>
  );
}

export const ApplicationInfo = connect(mapStateToProps)(ApplicationInfoComponent);
