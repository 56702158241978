import React, { useState, FC, useEffect } from 'react';
import clsx from 'clsx';
import styles from './ConfirmUserEmail.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { useLazyGetUsersQuery } from '../../redux/services/client';
import { useLazyCheckEmailAvialabilityQuery } from '../../redux/services/mail';
import { useDispatch } from 'react-redux';
import {
  useAddMailOrPhoneWithoutConfirmationMutation,
  useChangeMainEmailWithoutConfirmationMutation,
} from '../../redux/services/owner';
import { userApi } from '../../redux/services/user';

export const ConfirmUserEmail: FC = () => {
  const [addMailOrPhoneWithoutConfirmation] = useAddMailOrPhoneWithoutConfirmationMutation();
  const [changeMainEmailWithoutConfirmation] = useChangeMainEmailWithoutConfirmationMutation();
  const [getUsers] = useLazyGetUsersQuery();
  const [checkEmailAvialability] = useLazyCheckEmailAvialabilityQuery();
  const [emailError, setEmailError] = useState<null | string>(null);
  const [email, setEmail] = useState<string | undefined>();
  const history = useHistory();
  const { clientId, userId } = useParams<{ clientId: string; userId: string }>();
  const action = useLocation().pathname.split('/')[4];
  const dispatch = useDispatch();

  useEffect(() => {
    const start = async () => {
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: userId,
      });
      setEmail(users?.find((user) => user.user.id === Number(userId))?.user.email);
    };
    start();
  }, [clientId, userId]);

  const getEmailError = () => {
    const emailTest =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!email) return setEmailError('Укажите адрес электронной почты');
    if (email) {
      if (!emailTest.test(email)) {
        return setEmailError('Неверный формат адреса электронной почты');
      }
    }
  };

  const handleEmailAction = async () => {
    try {
      if (userId && email) {
        const { data } = await checkEmailAvialability(email);
        if (data && !data.isAvailable) {
          return setEmailError('Почтовый адрес уже используется');
        }
      }

      if (email && userId) {
        action === 'change'
          ? await changeMainEmailWithoutConfirmation({
              email: email,
              user_id: String(userId),
            })
          : await addMailOrPhoneWithoutConfirmation({
              identifier: email,
              user_id: String(userId),
            });
        dispatch(
          userApi.endpoints.getExternalAccounts.initiate(String(userId), {
            subscribe: false,
            forceRefetch: true,
          }),
        );
        history.push(`/application/user/${clientId}/${userId}`);
      }
    } catch (e) {
      console.log('checkEmailCodeError', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => history.push(`/application/user/${clientId}/${userId}`)}
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          {action === 'change' ? 'Изменить почту' : 'Добавить почту'}
        </Typography>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          {action === 'change'
            ? 'Укажите новый адрес электронной почты'
            : 'Укажите дополнительный адрес электронной почты'}
        </Typography>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={clsx('custom', styles.textfield)}
          onBlur={getEmailError}
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          error={!!emailError}
          helperText={emailError ? emailError : ''}
          fullWidth
          variant="standard"
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button
            onClick={() => history.push(`/application/user/${clientId}/${userId}`)}
            variant="custom"
            color="secondary"
          >
            Отмена
          </Button>
          <Button variant="custom" style={{ marginLeft: 24 }} onClick={handleEmailAction}>
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );
};
