import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { convertUserProfile } from '../../helpers';
import { setIsMenuOpen } from '../../redux/appSlice';
import { useRestoreProfileMutation } from '../../redux/services/user';
import { RootState } from '../../redux/store';
import { setUserProfile, TUserProfile } from '../../redux/userSlice';
import { getUserProfile } from '../../requests/oidc';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  deleted: state.user.userProfile.deleted,
});

export const RestoreProfileComponent = ({
  userId,
  deleted,
}: {
  userId: TUserProfile['id'];
  deleted: TUserProfile['deleted'];
}) => {
  const [restoreProfile] = useRestoreProfileMutation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userId && !deleted) {
      dispatch(setIsMenuOpen(true));
      return history.push('/profile');
    }
  }, [userId]);

  useEffect(() => {
    dispatch(setIsMenuOpen(false));
  }, []);

  const handleRestoreProfile = async () => {
    if (userId) {
      await restoreProfile({ userId: userId });
      const profile = await getUserProfile();
      if (profile) {
        const convertedProfile = convertUserProfile(profile);
        dispatch(setUserProfile(convertedProfile));
        dispatch(setIsMenuOpen(true));
        return history.push('/profile');
      }
    }
  };
  return (
    <Button
      style={{ marginLeft: 'auto', marginRight: 'auto', height: 'fit-content' }}
      onClick={handleRestoreProfile}
      variant="custom2"
    >
      Восстановить аккаунт
    </Button>
  );
};

export const RestoreProfile = connect(mapStateToProps)(RestoreProfileComponent);
