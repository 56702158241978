import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TNoticeSlice = {
  error?: string;
  id: number;
  isRead: boolean;
  message: string;
  statusCode?: number;
  timestamp: string;
  avatar?: string | null;
};

const initialState: Array<TNoticeSlice> = [];

const noticesSlice = createSlice({
  name: 'noticesSlice',
  initialState,
  reducers: {
    setNotice(state, action: PayloadAction<TNoticeSlice>) {
      state.unshift(action.payload);
    },
    deleteNotice(state, action: PayloadAction<number>) {
      return state.filter((notice) => notice.id !== action.payload);
    },
    deleteAllNotices() {
      return [];
    },
    setIsReadNotice(state, action: PayloadAction<boolean>) {
      const newArray = [...state];
      newArray.map((notice) => (notice.isRead = action.payload));
    },
  },
});

export const { setNotice, deleteNotice, deleteAllNotices, setIsReadNotice } = noticesSlice.actions;
export default noticesSlice.reducer;
