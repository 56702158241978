import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { FC, MouseEvent } from 'react';
import { ReactComponent as RadioIcon } from '../icons/Radio.svg';
import { ReactComponent as RadioCheckedIcon } from '../icons/RadioChecked.svg';
import styles from './CustomRadioButton.module.css';

export const CustomRadioButton: FC<{
  selected: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  radioButtonClass?: string;
}> = ({ children, selected, onClick, radioButtonClass }) => {
  return (
    <div className={styles.container}>
      <Button
        className={clsx('text-14', 'color-0B1641', radioButtonClass, styles['radio-button'])}
        onClick={onClick}
        startIcon={
          selected ? (
            <RadioCheckedIcon className={styles['radio-icon']} />
          ) : (
            <RadioIcon className={styles['radio-icon']} />
          )
        }
      >
        {children}
      </Button>
    </div>
  );
};
