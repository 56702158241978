import React, { useEffect, forwardRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { RootState } from '../../redux/store';
import { setIsNotificationPanelOpen, setIsSnackbarOpen, TAppSlice } from '../../redux/appSlice';
import { TNoticeSlice } from '../../redux/noticesSlice';
import clsx from 'clsx';
import styles from './CustomSnackbar.module.css';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from './Theme';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as AppAvatar } from '../../icons/AppAvatar.svg';
import { ReactComponent as Logo } from '../../icons/Logo2.svg';
import Avatar from '@mui/material/Avatar';

export enum ESnackBarVariant {
  error = 'error',
  notification = 'notification',
}
interface CustomSnackbarProps extends CustomContentProps {
  isNotificationPanelOpen: TAppSlice['isNotificationPanelOpen'];
  isSnackbarOpen: TAppSlice['isSnackbarOpen'];
  message?: string;
  notices?: Array<TNoticeSlice>;
  avatar?: string | null;
  snackbarVariant: ESnackBarVariant;
}

const mapStateToProps = (state: RootState) => ({
  isNotificationPanelOpen: state.app.isNotificationPanelOpen,
  isSnackbarOpen: state.app.isSnackbarOpen,
  notices: state.notices,
});

const CustomSnackbarComponent = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  ({ isNotificationPanelOpen, isSnackbarOpen, message, notices, avatar, snackbarVariant }, ref) => {
    const newNotices = notices?.filter((notice) => notice.isRead === false);
    const { closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setIsSnackbarOpen(!isSnackbarOpen));

      return () => {
        dispatch(setIsSnackbarOpen(isSnackbarOpen));
      };
    }, []);

    const onPanelIconClick = () => {
      dispatch(setIsNotificationPanelOpen(!isNotificationPanelOpen));
      closeSnackbar();
    };

    const appAvatar = () => (snackbarVariant === ESnackBarVariant.error ? <AppAvatar /> : <Logo />);

    return (
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <SnackbarContent ref={ref} onClick={onPanelIconClick}>
            <Card className={styles.card}>
              <div
                className={clsx(styles['avatar-wrapper'], {
                  [styles['avatar-wrapper-notification']]:
                    snackbarVariant === ESnackBarVariant.notification,
                })}
              >
                {avatar ? <Avatar src={avatar} className={styles.avatar} /> : appAvatar()}
              </div>
              <div className={styles['message-wrapper']}>
                <Typography
                  className={clsx('text-14', 'color-0B1641', styles.messages, {
                    [styles['one-notice']]: newNotices?.length && newNotices.length === 1,
                    [styles['with-counter']]: newNotices?.length && newNotices.length > 1,
                  })}
                >
                  {message}
                </Typography>
                {newNotices?.length && newNotices.length > 1 ? (
                  <Typography className={clsx('text-12', 'color-858BA0')}>
                    Ещё {newNotices.length - 1}
                  </Typography>
                ) : (
                  ''
                )}
              </div>
              <IconButton
                className={styles['close-button']}
                onClick={(e) => {
                  closeSnackbar();
                  e.stopPropagation();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Card>
          </SnackbarContent>
        </StyledEngineProvider>
      </ThemeProvider>
    );
  },
);

CustomSnackbarComponent.displayName = 'CustomSnackbar';

export const CustomSnackbar = connect(mapStateToProps, null, null, { forwardRef: true })(
  CustomSnackbarComponent,
);
