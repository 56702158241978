import React, { FC, useRef } from 'react';
import styles from './EventLogRightPanel.module.css';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { RootState } from '../redux/store';
import { TAppSlice } from '../redux/appSlice';
import { TLogger } from '../redux/services/logger';
import { ResizablePanel } from './applications/ResizablePanel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type TEventLogRightPanelProps = {
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  isMobile: TAppSlice['isMobile'];
  selectedEvent: Partial<TLogger> | null;
};

const mapStateToProps = (state: RootState) => ({
  isClientPanelOpen: state.app.isClientPanelOpen,
  isMobile: state.app.isMobile,
});

const EventLogRightPanelComponent: FC<TEventLogRightPanelProps> = ({
  isClientPanelOpen,
  isMobile,
  selectedEvent,
}) => {
  const hidden = isMobile || isClientPanelOpen;
  const panelRef = useRef<HTMLDivElement>(null);

  return (
    <ResizablePanel>
      <Box ref={panelRef} className={clsx(styles.panel, { [styles['panel-hidden']]: hidden })}>
        <Typography
          className={clsx('header-2-medium', 'font-golos', 'color-0B1641', styles['event-name'])}
        >
          {selectedEvent?.event}
        </Typography>
        {selectedEvent?.date && (
          <>
          <Typography className={clsx('text-15-medium', 'color-0B1641', styles.title)}>
            Дата и время
          </Typography>
          <Typography className={clsx('text-14', 'color-3C4567', styles.text)}>
            {selectedEvent?.date?.replace('T', ' ').replace('Z', '')}
          </Typography>
          </>
        )}
        {selectedEvent?.client_id && (
          <>
            <Typography className={clsx('text-15-medium', 'color-0B1641', styles.title)}>
              Приложение
            </Typography>
            <Typography className={clsx('text-14', 'color-3C4567', styles.text)}>
              {selectedEvent?.client_id}
            </Typography>
          </>
        )}
        {selectedEvent?.user_id && (
          <>
            <Typography className={clsx('text-15-medium', 'color-0B1641', styles.title)}>
              Пользователь
            </Typography>
            <Typography className={clsx('text-14', 'color-3C4567', styles.text)}>
              {selectedEvent?.user_id}
            </Typography>
          </>
        )}
        {selectedEvent?.ip_address && (
          <>
            <Typography className={clsx('text-15-medium', 'color-0B1641', styles.title)}>
              Местоположение
            </Typography>
            <Typography className={clsx('text-14', 'color-3C4567', styles.text)}>
              {selectedEvent?.ip_address?.replace('::ffff:', '')}
            </Typography>
          </>
        )}
        {selectedEvent?.device && (
          <>
            <Typography className={clsx('text-15-medium', 'color-0B1641', styles.title)}>
              Устройство
            </Typography>
            <Typography className={clsx('text-14', 'color-3C4567', styles.text)}>
              {selectedEvent?.device}
            </Typography>
          </>
        )}
        {selectedEvent?.description && (
          <>
            <Typography className={clsx('text-15-medium', 'color-0B1641', styles.title)}>
              Описание
            </Typography>
            <Typography className={clsx('text-14', 'color-3C4567', styles.text)}>
              {selectedEvent?.description}
            </Typography>
          </>
        )}
      </Box>
    </ResizablePanel>
  );
};

export const EventLogRightPanel = connect(mapStateToProps)(EventLogRightPanelComponent);