import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styles from './FillProfile.module.css';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { useAddEmailMutation, useChangePasswordMutation } from '../../redux/services/user';
import { useHistory } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { connect, useDispatch } from 'react-redux';
import { getUserProfile } from '../../requests/oidc';
import { convertUserProfile, generateYearsBetween } from '../../helpers';
import { setUserProfile, TUserProfile } from '../../redux/userSlice';
import { ReactComponent as ArrowDropDownIcon } from '../../icons/ArrowDropDown.svg';
import { ReactComponent as CheckIcon } from '../../icons/CheckMark.svg';
import { updateUser } from '../../requests/user';
import { RootState } from '../../redux/store';
import { setIsMenuOpen, TAppSlice } from '../../redux/appSlice';
import {
  MailCodeTypes,
  useLazyCheckEmailAvialabilityQuery,
  useSendEmailCodeMutation,
} from '../../redux/services/mail';
import { AGE_VALIDATION_RULES } from '../../constants';

const mapStateToProps = (state: RootState) => ({
  passwordChangeRequired: state.user.userProfile.password_change_required,
  email: state.user.userProfile.email,
  birthdate: state.user.userProfile.birthdate,
  userId: state.user.userProfile.id,
  nickname: state.user.userProfile.nickname,
  isMobile: state.app.isMobile,
});

const FillProfileComponent = ({
  email,
  birthdate,
  passwordChangeRequired,
  userId,
  nickname,
  isMobile,
}: {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  nickname: TUserProfile['nickname'];
  isMobile: TAppSlice['isMobile'];
}) => {
  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsMenuOpen(false));
  }, []);

  useEffect(() => {
    if (userId) {
      if (email && birthdate && !passwordChangeRequired) history.push('/profile');
      if (passwordChangeRequired) setSteps((steps) => [...steps, 'Пароль']);
      if (!email) setSteps((steps) => [...steps, 'Электронная почта']);
      if (!birthdate) setSteps((steps) => [...steps, 'Дата рождения']);
    }
  }, [userId]);

  const getFieldByStep = (step: number) => {
    switch (steps[step]) {
      case 'Пароль':
        return (
          <FieldPassword
            isMobile={isMobile}
            userId={userId}
            steps={steps}
            step={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'Электронная почта':
        return (
          <FieldEmail
            isMobile={isMobile}
            nickname={nickname}
            steps={steps}
            userId={userId}
            step={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'Дата рождения':
        return <FieldDateOfBirth isMobile={isMobile} userId={userId} />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Typography className={clsx('color-0B1641', 'header-2-medium')} style={{ marginBottom: 24 }}>
        Заполнение профиля
      </Typography>
      <Typography className={clsx('color-858BA0', 'text-14')} style={{ marginBottom: 24 }}>
        Ваш профиль был создан автоматически. Для завершения регистрации укажите необходимые данные.
      </Typography>
      <Stepper style={{ marginBottom: 42 }} className="custom" activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getFieldByStep(activeStep)}
    </div>
  );
};

export const FillProfile = connect(mapStateToProps)(FillProfileComponent);

const FieldPassword = ({
  step,
  setActiveStep,
  steps,
  userId = '0',
  isMobile,
}: {
  userId?: string;
  step: number;
  steps: string[];
  setActiveStep: Dispatch<SetStateAction<number>>;
  isMobile: TAppSlice['isMobile'];
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [changePassword] = useChangePasswordMutation();

  const handleChangePassword = async () => {
    try {
      if (!newPassword) return setNewPasswordError('Укажите пароль');
      if (!repeatPassword) return setRepeatPasswordError('Укажите пароль');
      if (repeatPassword !== newPassword) return setRepeatPasswordError('Пароли не совпадают');
      if (newPasswordError || repeatPasswordError) return;
      await changePassword({ newPassword, userId: userId }).unwrap();
      if (step === steps.length - 1) {
        const profile = await getUserProfile();
        if (profile) {
          const convertedProfile = convertUserProfile(profile);
          dispatch(setUserProfile(convertedProfile));
          if (!isMobile) dispatch(setIsMenuOpen(true));
          return history.push('/profile');
        }
      }
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const getNewPasswordError = () => {
    if (!newPassword) return setNewPasswordError('Укажите пароль');
    if (newPassword.length < 8) return setNewPasswordError('Используйте не менее 8 символов');
    if (newPassword.length > 32) return setNewPasswordError('Используйте не более 32 символов');
  };

  const getRepeatPasswordError = () => {
    if (!repeatPassword) return setRepeatPasswordError('Укажите пароль');
    if (repeatPassword !== newPassword) return setRepeatPasswordError('Пароли не совпадают');
  };

  return (
    <>
      <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
        Пароль
      </Typography>
      <PasswordTextfield
        value={newPassword}
        onChange={(e) => {
          const { value } = e.target;
          if (newPasswordError) setNewPasswordError('');
          if (value === repeatPassword && repeatPasswordError === 'Пароли не совпадают')
            setRepeatPasswordError('');
          setNewPassword(e.target.value);
        }}
        onBlur={getNewPasswordError}
        className="custom"
        style={{ marginBottom: 24 }}
        FormHelperTextProps={{
          className: clsx('color-FC545C', 'text-14'),
        }}
        error={!!newPasswordError}
        helperText={newPasswordError ? newPasswordError : ''}
        fullWidth
        variant="standard"
      />
      <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
        Повторите пароль
      </Typography>
      <PasswordTextfield
        value={repeatPassword}
        onChange={(e) => {
          if (repeatPasswordError) setRepeatPasswordError('');
          setRepeatPassword(e.target.value);
        }}
        onBlur={getRepeatPasswordError}
        className=" custom"
        fullWidth
        variant="standard"
        FormHelperTextProps={{
          className: clsx('color-FC545C', 'text-14'),
        }}
        error={!!repeatPasswordError}
        helperText={repeatPasswordError ? repeatPasswordError : ''}
      />
      <Button
        className={styles['continue-button']}
        variant={'custom'}
        onClick={() => handleChangePassword()}
      >
        Продолжить
      </Button>
    </>
  );
};

const FieldEmail = ({
  userId,
  step,
  steps,
  setActiveStep,
  nickname,
  isMobile,
}: {
  userId: TUserProfile['id'];
  nickname: TUserProfile['nickname'];
  step: number;
  steps: string[];
  setActiveStep: Dispatch<SetStateAction<number>>;
  isMobile: TAppSlice['isMobile'];
}) => {
  const [addEmail] = useAddEmailMutation();
  const [emailError, setEmailError] = useState('');
  const [codeError, setCodeError] = useState<null | string>(null);
  const [isMailSent, setIsMailSent] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [[minute, second], setTime] = useState<number[]>([1, 30]);
  const [code, setCode] = useState('');
  const [resendCode, setResendCode] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendEmailCode] = useSendEmailCodeMutation();
  const [checkEmailAvialability] = useLazyCheckEmailAvialabilityQuery();

  const getEmailError = () => {
    const emailTest =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!newEmail) return setEmailError('Укажите адрес электронной почты');
    if (newEmail) {
      if (!emailTest.test(newEmail)) {
        return setEmailError('Неверный формат адреса электронной почты');
      }
    }
    return setEmailError('');
  };

  const getCodeError = () => {
    if (!code) return setCodeError('Укажите код подтверждения');
  };

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  useEffect(() => {
    setTime([1, 30]);
    getCodeError();
  }, [isMailSent]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const handleAddEmail = async () => {
    try {
      if (newEmail && userId) {
        const result = await addEmail({
          newEmail,
          userId,
          code,
          rebind: false,
        });
        if ((result as { error: FetchBaseQueryError | SerializedError }).error)
          return setCodeError('Неверный код подтверждения');
        const profile = await getUserProfile();
        if (profile && step === steps.length - 1) {
          const convertedProfile = convertUserProfile(profile);
          dispatch(setUserProfile(convertedProfile));
          if (!isMobile) dispatch(setIsMenuOpen(true));
          return history.push('/profile');
        }
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (e) {
      console.log('checkEmailCodeError', e);
    }
  };

  const changeEmailInput = () => {
    setIsMailSent(!isMailSent);
  };

  const sendConfirmationCode = async () => {
    try {
      if (userId && newEmail) {
        const response = await checkEmailAvialability(newEmail).unwrap();
        if (!response.isAvailable) {
          return setEmailError('Почтовый адрес уже используется');
        }
        const mailCodeResponse = await sendEmailCode({
          type: MailCodeTypes.addEmail,
          userId: userId,
          email: newEmail,
          name: nickname,
          resend: resendCode,
        }).unwrap();
        if (mailCodeResponse?.message === 'code.still.active') {
          setResendCode(true);
          setIsMailSent(true);
        }
        setIsMailSent(true);
        setTime([1, 30]);
      }
    } catch (e) {
      console.log('sendConfirmationCodeError ', e);
    }
  };
  return (
    <>
      <div className={styles['change-email']}>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          Адрес электронной почты
        </Typography>
        <TextField
          disabled={isMailSent}
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className={clsx('custom', styles.textfield)}
          onBlur={getEmailError}
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          error={!!emailError}
          helperText={emailError || 'На этот адрес будет отправлен код подтверждения'}
          fullWidth
          variant="standard"
        />
        {isMailSent && (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Введите код, отправленный на указанный адрес
            </Typography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              onBlur={getCodeError}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <Typography className={clsx('text-14', 'color-858BA0', 'custom-2')}>
                  Выслать код повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </Typography>
              ) : (
                <Button variant="custom2" onClick={sendConfirmationCode}>
                  Выслать код повторно
                </Button>
              )}
              <Button onClick={changeEmailInput} variant="custom2" style={{ padding: 0 }}>
                Изменить почту
              </Button>
            </div>
          </>
        )}
        <Button
          className={styles['continue-button']}
          variant={'custom'}
          onClick={isMailSent ? handleAddEmail : sendConfirmationCode}
        >
          {isMailSent ? 'Подтвердить' : 'Получить код'}
        </Button>
      </div>
    </>
  );
};

const FieldDateOfBirth = ({
  userId = '0',
  isMobile,
}: {
  userId: TUserProfile['id'];
  isMobile: TAppSlice['isMobile'];
}) => {
  const dateNow = new Date();
  const [birthDay, setBirthDay] = useState(dateNow.getDate());
  const [birthYear, setBirthYear] = useState(dateNow.getFullYear() - 14);
  const months = [
    { name: 'январь', days: 31 },
    { name: 'февраль', days: +birthYear % 4 === 0 ? 29 : 28 },
    { name: 'март', days: 31 },
    { name: 'апрель', days: 30 },
    { name: 'май', days: 31 },
    { name: 'июнь', days: 30 },
    { name: 'июль', days: 31 },
    { name: 'август', days: 31 },
    { name: 'сентябрь', days: 30 },
    { name: 'октябрь', days: 31 },
    { name: 'ноябрь', days: 30 },
    { name: 'декабрь', days: 31 },
  ];
  const [birthMonth, setBirthMont] = useState(months[dateNow.getMonth()]?.name);
  const selectedMonth = months.find((month) => month.name === birthMonth);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setBirthDay(Math.min(selectedMonth?.days || 0, birthDay));
  }, [birthMonth, birthYear]);

  const birthDate = new Date(
    +birthYear,
    months.findIndex((month) => month.name === birthMonth),
    +birthDay,
  );
  const isDateError = () => {
    if (dateNow.getFullYear() - birthDate.getFullYear() < AGE_VALIDATION_RULES.min_age) return true;
    if (dateNow.getFullYear() - birthDate.getFullYear() === AGE_VALIDATION_RULES.min_age) {
      if (dateNow.getMonth() < birthDate.getMonth()) return true;
      if (dateNow.getMonth() === birthDate.getMonth() && dateNow.getDate() < birthDate.getDate())
        return true;
    }
    if (dateNow.getFullYear() - birthDate.getFullYear() > AGE_VALIDATION_RULES.max_age) return true;
    if (dateNow.getFullYear() - birthDate.getFullYear() === AGE_VALIDATION_RULES.max_age) {
      if (dateNow.getMonth() > birthDate.getMonth()) return true;
      if (dateNow.getMonth() === birthDate.getMonth() && dateNow.getDate() > birthDate.getDate())
        return true;
    }
    return false;
  };

  const handleContinue = async () => {
    const month = months.findIndex(({ name }) => name === birthMonth);
    if (isDateError()) return;
    await updateUser(
      { birthdate: new Date(Date.UTC(+birthYear, month, birthDay)).toISOString() },
      userId,
    );
    const profile = await getUserProfile();
    if (profile) {
      const convertedProfile = convertUserProfile(profile);
      dispatch(setUserProfile(convertedProfile));
      if (!isMobile) dispatch(setIsMenuOpen(true));
      return history.push('/profile');
    }
  };

  return (
    <>
      <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
        Дата рождения
      </Typography>
      <div style={{ display: 'flex' }}>
        <Select
          IconComponent={ArrowDropDownIcon}
          MenuProps={{ classes: { paper: styles.menu } }}
          className={styles.select}
          classes={{ outlined: styles.outlined }}
          sx={{
            '&.Mui-focused fieldset': {
              borderColor: '#606783 !important',
            },
            '&.Mui-error fieldset': {
              borderColor: '#FC545C !important',
            },
          }}
          error={isDateError()}
          value={birthDay}
          renderValue={(value) => `0${value}`.slice(-2)}
          onChange={(e) => setBirthDay(e.target.value as number)}
        >
          {new Array(selectedMonth?.days).fill(null).map((_, index) => (
            <MenuItem
              classes={{
                root: styles['select-item'],
                selected: styles['select-item-selected'],
              }}
              key={index}
              value={index + 1}
            >
              {index + 1}
              <CheckIcon className={styles['check-icon']} />
            </MenuItem>
          ))}
        </Select>
        <Select
          IconComponent={ArrowDropDownIcon}
          MenuProps={{ classes: { paper: styles.menu } }}
          className={styles.select}
          classes={{ outlined: styles.outlined }}
          sx={{
            '&.Mui-focused fieldset': {
              borderColor: '#606783 !important',
            },
            '&.Mui-error fieldset': {
              borderColor: '#FC545C !important',
            },
          }}
          error={isDateError()}
          value={birthMonth}
          onChange={(e) => {
            setBirthMont(e.target.value);
          }}
        >
          {months.map((month) => (
            <MenuItem
              classes={{
                root: styles['select-item'],
                selected: styles['select-item-selected'],
              }}
              key={month.name}
              value={month.name}
            >
              {month.name}
              <CheckIcon className={styles['check-icon']} />
            </MenuItem>
          ))}
        </Select>
        <Select
          IconComponent={ArrowDropDownIcon}
          MenuProps={{ classes: { paper: styles.menu } }}
          className={styles.select}
          classes={{ outlined: styles.outlined }}
          sx={{
            '&.Mui-focused fieldset': {
              borderColor: '#606783 !important',
            },
            '&.Mui-error fieldset': {
              borderColor: '#FC545C !important',
            },
          }}
          error={isDateError()}
          value={birthYear}
          onChange={(e) => setBirthYear(e.target.value as number)}
        >
          {generateYearsBetween(
            new Date().getFullYear() - AGE_VALIDATION_RULES.max_age,
            new Date().getFullYear() - AGE_VALIDATION_RULES.min_age,
          ).map((year) => (
            <MenuItem
              classes={{
                root: styles['select-item'],
                selected: styles['select-item-selected'],
              }}
              key={year}
              value={year}
            >
              {year}
              <CheckIcon className={styles['check-icon']} />
            </MenuItem>
          ))}
        </Select>
      </div>
      {isDateError() && (
        <Typography style={{ marginTop: 8 }} className={clsx('color-FC545C', 'sf-16-reg')}>
          Дата рождения не соответствует возрастным ограничениям
        </Typography>
      )}
      <Button className={styles['continue-button']} variant={'custom'} onClick={handleContinue}>
        Продолжить
      </Button>
    </>
  );
};
