import { FunctionComponent } from 'react';
import { ReactComponent as AvatarIcon } from '../icons/Avatar.svg';
import { ReactComponent as MailIcon } from '../icons/Mail.svg';
import { ReactComponent as PhoneIcon } from '../icons/Phone.svg';

const profileScope = {
  description: 'Просмотр имени, фото и даты рождения',
  icon: AvatarIcon,
};

const emailScope = {
  description: 'Просмотр адреса электронной почты',
  icon: MailIcon,
};

const phoneScope = {
  description: 'Просмотр номера телефона',
  icon: PhoneIcon,
};

export const getScopeProps = (
  scope: string,
): { description: string; icon: FunctionComponent } | undefined => {
  switch (scope) {
    case 'profile':
      return profileScope;
    case 'email':
      return emailScope;
    case 'phone':
      return phoneScope;
    default:
      return undefined;
  }
};
