import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import styles from './PasswordTextfield.module.css';
import { ReactComponent as EyeIcon } from '../../icons/Eye.svg';
import { ReactComponent as EyeOffIcon } from '../../icons/EyeOff.svg';
import { ReactComponent as CopyIcon } from '../../icons/Copy.svg';

export const PasswordTextfield = (textfieldProps: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      {...textfieldProps}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              placement="bottom-end"
              arrow
              title={showPassword ? 'Скрыть' : 'Показать'}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <>
                <IconButton
                  className={styles['input-adornment-button']}
                  onClick={() => {
                    setShowPassword((show) => !show);
                  }}
                >
                  {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                </IconButton>
                {textfieldProps.id === 'copy' && (
                  <IconButton
                    className={styles['input-adornment-button']}
                    onClick={() => {
                      navigator.clipboard.writeText(String(textfieldProps.value));
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                )}
              </>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};
