import React, { FC } from 'react';
import { ReactComponent as SadIcon } from '../icons/Sad.svg';
import { useParams } from 'react-router-dom';
import styles from './Error.module.css';

export const Error: FC = () => {
  const { text } = useParams<{ text: string }>();

  return (
    <div className={styles.wrapper}>
      <div>
        <SadIcon className={styles['sad-icon']} />
      </div>
      {text}
    </div>
  );
};
